define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["en-us", {
    "account": {
      "basicInfo": {
        "title": "Basic Info"
      },
      "chargeInfo": {
        "title": "Charge Info"
      },
      "contact": {
        "action": {
          "edit": "Edit contact"
        },
        "administrator": "Administrator",
        "billing": "Billing",
        "description": {
          "administrator": "Person responsible for the company account within NFe.io. This user will receive important notifications, like invoices, renewals, updates and etc.",
          "billing": "Person responsible for the company account within NFe.io. This user will receive important notifications, like invoices, renewals, updates and etc.",
          "technical": "Person responsible for the company account within NFe.io. This user will receive important notifications, like invoices, renewals, updates and etc."
        },
        "dialog": {
          "edit": {
            "title": "Edit contact"
          }
        },
        "empty": {
          "description": "No contact registered. All notifications will be sent only to the administrator.",
          "title": "Add a {contactType} contact"
        },
        "subtitle": {
          "administrator": "Responsible administrator",
          "billing": "Responsible for billing",
          "technical": "Technical responsible"
        },
        "technical": "Technical Manager",
        "title": "Contact",
        "type": "Type"
      },
      "errorMessages": {
        "email": "Enter a valid valid email",
        "phone": "Enter a valid phone number"
      },
      "helperText": {
        "email": {
          "admin": "To change this information, contact support +55 (11) 4063-8091"
        }
      },
      "pageTitle": "Account",
      "title": "Account"
    },
    "accounts": {
      "emptyState": {
        "description": "Try other terms. For telephone number do not use neither the country nor the region code. For the Federal Tax Number use all ponctuation signs and symbols. If you do not find the desired account contact support to solve the problem.",
        "title": "No accounts found for search term"
      },
      "errorState": {
        "description": "Try other terms. For telephone number do not use neither the country nor the region code. For the Federal Tax Number use all ponctuation signs and symbols. If you do not find the desired account contact support to solve the problem.",
        "title": "An error has occurred on search"
      },
      "pageTitle": "Accounts",
      "title": "Accounts",
      "useAs": "Use as subscription",
      "useAsSubscriptionId": "Use as subscription {id}"
    },
    "actions": "Actions",
    "activate": "Activate",
    "add": "Add",
    "address": "Address",
    "admin": {
      "panelTitle": "Administrator Panel",
      "title": "Administrator"
    },
    "agreement": {
      "actionActivate": "Reactivate",
      "actionOpenTerms": "View Terms",
      "actionPrintTermsAnnex": "Print Annex",
      "actionSign": "Sign",
      "actionSwitch": "Show another agreement",
      "address": {
        "additionalInformation": "Additional Info",
        "city": {
          "label": "City",
          "searchPlaceholder": "Search for a City"
        },
        "country": {
          "label": "Country",
          "selectCountry": "Select a country"
        },
        "district": "District",
        "header": "Address",
        "number": "Number",
        "postalCode": "Postal Code",
        "state": {
          "label": "State",
          "searchPlaceholder": "Search for a State"
        },
        "street": "Street"
      },
      "agreement-print": {
        "availableServices": "Services available and agreed",
        "details": {
          "title": "Agreement details"
        },
        "info": "This document is bond to CONDIÇÕES GERAIS DE LICENCIAMENTO DE SOFTWARE E PRESTAÇÃO DE SERVIÇOS.",
        "product": {
          "billCycleType": {
            "ActivationBased": "Activation based",
            "AgreementBased": "Agreement based",
            "UsageBased": "Usage based"
          },
          "price": "Price"
        },
        "title": "Annex"
      },
      "billingCard": {
        "beginOn": "Begin on",
        "cardTitle": "{brand} Final {number}",
        "cardValidAt": "Expires at {month}/{year}",
        "endOn": "End on",
        "frequency": "Frequency",
        "indefinite": "Indefinite",
        "paymentMethods": "Payment Methods",
        "preferredPaymentMethod": "Preferred Payment Method",
        "salesTerm": "Sales Term",
        "title": "Billing"
      },
      "detailsCard": {
        "beginOn": "Begin on",
        "contractEscalationPriceIndex": "Monetary correction index",
        "contractEscalationType": "Correction application method",
        "customTerm": "Additional term",
        "duration": "Duration",
        "durationFormat": "{duration} {frequency}",
        "einvoiceDescription": "Electronic Invoice description",
        "endOn": "End on",
        "goToInvoices": "Go to invoices",
        "indefinite": "Indefinite",
        "minimumDeductibleAmount": "Minimum deductible amount",
        "notificationTermination": "Notification for Termination",
        "signedOn": "Signed on",
        "title": "Details"
      },
      "pageTitle": "Agreement #{number}",
      "productListCard": {
        "filter_all": "All",
        "filter_minimumQuantity": "Minimum Usage Set",
        "table": {
          "headers": {
            "cycles": "Cycles",
            "discountCycles": "Discount Cycles",
            "minimumQuantity": "Minimum Usage",
            "product": "Product"
          }
        },
        "title": "Agreement Products"
      },
      "productPriceRates": {
        "table": {
          "headers": {
            "begin": "Qtd. Início",
            "end": "Qtd. Final",
            "listPrice": "Valor Unitário",
            "overageListPrice": "Valor Excedente"
          }
        },
        "title": "Faixas de Preço do Produto"
      },
      "status": {
        "Active": "Active",
        "Cancelled": "Cancelled",
        "Draft": "Draft",
        "Expired": "Expired",
        "Suspended": "Suspended"
      },
      "statusLabel": "Agreement status",
      "subscriptionsCard": {
        "title": "Subscriptions"
      },
      "supportCard": {
        "availableMedia": "Available means of communication",
        "openingHours": "Opening hours",
        "title": "Support"
      },
      "title": "Agreement"
    },
    "amount": "Amount",
    "apikeys": {
      "clientId": "Client ID",
      "clients": {
        "title": "Clients and Secrets"
      },
      "emptyState": {
        "description": "You need to have a subscription registered to be able to consult it, for that you need to talk to the sales team.",
        "title": "No access keys registered in your account"
      },
      "errorState": {
        "description": "There was an error loading your list of registered access keys to your subscription, please try again or contact support to resolve the issue.",
        "title": "There was a failure to load your access keys"
      },
      "group": "Keys",
      "keys": {
        "title": "List of API keys"
      },
      "status": {
        "Active": "Active",
        "Inactive": "Inactive"
      },
      "table": {
        "actions": "Actions",
        "createdOn": "Created on",
        "description": "Description",
        "expiration": "Expiration",
        "id": "Key",
        "key": "Key",
        "modifiedOn": "Modified on",
        "status": "Status",
        "type": "Type"
      },
      "title": "Access keys"
    },
    "bankName": "{name} bank",
    "cancel": "Cancel",
    "close": "Close",
    "components": {
      "datepicker": {
        "value_invalid": "The date is not valid",
        "value_out-of-range": "The date must be between '{'min'}' and '{'max'}'"
      }
    },
    "contractEscalationPriceIndex": {
      "IGPM": "IGP-M",
      "IPCA": "IPCA"
    },
    "contractEscalationType": {
      "Addendum": "By Addendum",
      "Automatically": "Automatically"
    },
    "copied": "Copied!",
    "copy": "Copy",
    "createAccount": {
      "companyName": "Company name",
      "contactDescription": "The contact information is for our commercial team clarify and send more details about our products and prices.",
      "contactTitle": "Contacts",
      "create": "Criar conta",
      "creating": "Creating account",
      "infoDescription": "Accounts are responsible by companies management, invoices and products acquired. You can create other accounts later.",
      "infoTitle": "Account name",
      "title": "Create account"
    },
    "creditCard": "Credit Card",
    "dataTable": {
      "emptyState": {
        "title": "No records found"
      },
      "errorState": {
        "title": "Error loading records"
      },
      "headers": {
        "effectiveOn": "Effective On",
        "invoice": "Invoice",
        "invoiceTotal": "Invoice Total"
      },
      "pagination": {
        "pageRange": "{firstItem} - {lastItem} of {total}",
        "perPage": "Rows per page"
      },
      "preInvoices": "Orders"
    },
    "days": "Day(s)",
    "description": "Description",
    "drawer": {
      "accountInfo": "Overview",
      "aggreement": "Aggrements",
      "apikeys": "Access keys",
      "invoices": "Invoices",
      "paymentMethods": "Payment Methods",
      "preInvoices": "Orders",
      "subscriptions": "Subscriptions"
    },
    "drawerAdmin": {
      "accounts": "Accounts"
    },
    "duration": {
      "Annual": "Years",
      "Bimonthly": "Two-month period",
      "Monthly": "Meses",
      "Quarterly": "Quarters",
      "Semester": "Semester"
    },
    "edit": "Edit",
    "email": "Email",
    "environments": {
      "development": "Test",
      "production": "Production",
      "sandbox": "Test",
      "test": "Test"
    },
    "errorMessages": {
      "address": {
        "cityCode": "Minimum of 7 characters",
        "districtOrCounty": "Minimum of 2 letters",
        "line1": "Minimum of 4 letters",
        "postalCode": "Follow the patern 99999-999"
      },
      "creditCardForm": {
        "default": "Unexpected failure when registering your Credit Card. Contact our support (11) 4063-8091",
        "expirationDate": "Enter a date later than today",
        "invalidMonth": "Invalid credit card expiration month",
        "invalidName": "Invalid card holder Name, must have first and last name",
        "invalidNumber": "Invalid credit card number",
        "invalidSecurityCode": "Invalid credit card CVC",
        "invalidYear": "Invalid credit card expiration year",
        "name": "The name as is on your card",
        "number": "Enter a valid credit card number",
        "securityCode": "Enter a security code with 3 or 4 digits"
      },
      "default": {
        "federalTaxNumber": "CNPJ invalid",
        "notEmpty": "Can not be empty"
      },
      "subscription": "Assinatura",
      "totalAmount": "Total Amount"
    },
    "export": "Export",
    "exports": {
      "emptyState": {
        "description": "We didn't find any exports. You can export an account usage report or a NFS-e batch on your company invoice list.",
        "title": "No exports found"
      },
      "emptyTable": {
        "text": "We didn't find any exports. You can export an account usage report or a NFS-e batch on your company invoice list.",
        "title": "No exports found"
      },
      "errorState": {
        "description": "There was an error loading your list of exports, please try again or contact support to resolve the issue.",
        "title": "There was a failure loading your exports"
      },
      "gotToExports": "Got to exports",
      "resource": {
        "account-usage": "Account usage",
        "company-product-invoice-analytical-csv": "NF-e report - CSV",
        "company-product-invoice-pdf": "NF-e batch - PDF",
        "company-product-invoice-xml": "NF-e batch - XML",
        "company-service-invoice-analytical-csv": "NFS-e report - CSV",
        "company-service-invoice-pdf": "NFS-e batch - PDF",
        "company-service-invoice-xml": "NFS-e batch - XML"
      },
      "status": {
        "Compressing": "Compressing",
        "Done": "Done",
        "Exporting": "Exporting",
        "Failed": "Failed",
        "None": "None",
        "Pending": "Pending",
        "Running": "Running"
      },
      "table": {
        "actions": {
          "download": "Download export",
          "title": "Actions"
        },
        "createdOn": "Created on",
        "date": "Date",
        "timeSpent": "Time spent",
        "totalLines": "N°. of lines",
        "type": "Type"
      },
      "text": "Here you will see a list of all requested usage reports and can download them.",
      "title": "Exports list"
    },
    "false": "No",
    "federalTaxNumber": "Federal Tax Number",
    "frequency": {
      "Annual": "Yearly",
      "Bimonthly": "Bimonthly",
      "Monthly": "Monthly",
      "Quarterly": "Quarterly",
      "Semester": "Semiannually"
    },
    "goBack": "Go back",
    "goToOld": "Back to old version",
    "helpCenter": {
      "apisReference": "APIs Reference",
      "contactUs": "Contact us",
      "docs": "Documentation",
      "nfsFirstSteps": "NFSe First steps",
      "openTicket": "Open Ticket",
      "serviceCodeRequest": "Cadastro de código de serviço"
    },
    "id": "ID",
    "identity": {
      "accountManagement": "Manage your user account"
    },
    "impersonate": {
      "companion": {
        "action": "Set as current account",
        "description": "You are <strong>previewing</strong> this account data. Do you want to set it as the current account?"
      }
    },
    "inactivate": "Inactivate",
    "invoice": {
      "code": "Identifier code",
      "dueOn": "Due On",
      "effectiveOn": "Effective On",
      "subscription": "Subscription",
      "title": "Invoice",
      "total": "Invoice Total"
    },
    "invoices": {
      "download": {
        "pdf": "PDF EInvoice",
        "xml": "XML EInvoice"
      },
      "emptyState": {
        "description": "You need to have a registered invoice so that you can consult them, for that you need to talk to the sales team.",
        "title": "No invoice registered in your account"
      },
      "errorState": {
        "description": "There was an error loading your list of registered invoices to your account, please try again or contact support to resolve the issue.",
        "title": "There was a failure to load your invoices"
      },
      "status": {
        "AwaitingAccounting": "Awaiting accounting",
        "AwaitingEInvoice": "Awaiting EInvoice",
        "AwaitingPayment": "Awaiting payment",
        "AwaitingPaymentMethod": "Awaiting payment method",
        "Cancelled": "Cancelled",
        "Paid": "Paid",
        "PartiallyPaid": "Partially paid",
        "PaymentDone": "Payment done",
        "Pending": "Pending",
        "Refunded": "Refunded"
      },
      "title": "Invoices",
      "titleSingular": "Invoice"
    },
    "issuerName": "{name} issuer",
    "legalName": "Company name",
    "logout": "Logout",
    "name": "Name",
    "needHelp": "Need help?",
    "no": "No",
    "order": {
      "customerInfo": "Customer Info",
      "merchantInfo": "Merchant Info",
      "status": {
        "Approved": "Approved",
        "Canceled": "Canceled",
        "Declined": "Declined",
        "Done": "Billed",
        "Draft": "New",
        "Executing": "In progress",
        "Mistaken": "Mistaken",
        "NotBilled": "Not billed",
        "Refused": "Refused",
        "WaitingApproval": "Waiting",
        "WaitingBilling": "Billing",
        "unknown": "Unknown"
      }
    },
    "orders": {
      "approve": {
        "description": "Do you really want to approve this Pre-Invoice?",
        "title": "Approve Order"
      },
      "emptyState": {
        "description": "You need to have an order registered to be able to consult them, for that you need to talk to the sales team.",
        "title": "No order registered in your account"
      },
      "errorState": {
        "description": "There was an error loading your list of registered orders to your account, please try again or contact support to resolve the issue.",
        "title": "There was a failure to load your orders"
      },
      "pageTitle": "Order #{number}",
      "refuse": {
        "description": "Let us know why you are refusing this Pre-Invoice.",
        "title": "Refuse Order"
      },
      "status": {
        "Approved": "Approved",
        "Declined": "Declined",
        "Done": "Billed",
        "Draft": "New",
        "Executing": "In progress",
        "NotBilled": "Not billed",
        "Refused": "Refused",
        "WaitingApproval": "Waiting approval",
        "WaitingBilling": "Billing",
        "unknown": "Unknown"
      },
      "title": "Orders",
      "titleSingular": "Order"
    },
    "pageTitle": "Accounts | NFE.io",
    "pay": "Pay",
    "payment": {
      "code": "Payment code",
      "customerInfo": "Customer Info",
      "dialog": {
        "bankSlip": {
          "copy": "Copy",
          "download": "Download",
          "identification": "Type line code",
          "text": "Look in your bank application or digital account for the payment option via bank slip and copy and paste the numbers above.",
          "title": "Pay with Bank Slip"
        },
        "creditCard": {
          "checkCode": "CVC",
          "expirationData": "MM/YY",
          "name": "Name (same on the card)",
          "number": "Card Number",
          "title": "New Credit Card"
        },
        "error": {
          "creditCardData": "Please check your details or contact your card operator.",
          "statusPending": "Charge can only be executed on Payments with Status equals to Pending status.",
          "title": "Payment refused",
          "unavailable": "Our payment system is unavailable. Contact our support (11) 4063-8091."
        },
        "instantPayment": {
          "copy": "Copy code",
          "identification": "Pix Copy & Paste",
          "text": "Look in your bank app or digital account for the PIX payment option and scan the QR Code above.",
          "title": "Pix payment"
        },
        "paymentMethods": {
          "errorMessages": {
            "expiredOn": "Expired on"
          },
          "expiresOn": "Expires on",
          "new": "Add Credit Card",
          "title": "Select payment method",
          "type": {
            "BankSlip": "Bank slip",
            "CreditCard": "Credit Card",
            "InstantPayment": "Instant Payment",
            "WireTransfer": "Wire Transfer"
          }
        },
        "running": "Processing payment",
        "runningBankSlip": "Generating bank slip",
        "runningPix": "Generating PIX",
        "success": {
          "title": "Thank you for your payment",
          "waitingRelease": "We will soon receive confirmation of your payment."
        },
        "wireTransfer": {
          "fields": {
            "accountNumber": "Account Number",
            "bank": "Bank",
            "bankBranchCode": "Agency",
            "identityNumber": "CNPJ"
          },
          "title": "Wire transfer"
        }
      },
      "discount": "Discount",
      "heading": "Payment",
      "merchantInfo": "Merchant Info",
      "paidAmount": "Paid Amount",
      "paidOn": "Paid on",
      "paymentMethod": "Payment Method",
      "penalty": "Penalty",
      "status": {
        "Cancelled": "Cancelled",
        "DivergentPaid": "Divergent Paid",
        "Expired": "Expired",
        "New": "New",
        "Paid": "Paid",
        "PartiallyPaid": "Partially Paid",
        "Pending": "Pending",
        "Refunded": "Refunded",
        "WaitingGateway": "Waiting Gateway"
      },
      "statusLabel": "Payment Status",
      "subtotal": "Subtotal",
      "taxWithheld": "Tax Withheld",
      "title": "Payment #{number}",
      "total": "Total"
    },
    "paymentMethodTypes": {
      "BankSlip": "Bank Slip",
      "CreditCard": "Credit Card",
      "DigitalWallet": "Digital Wallet",
      "InstantPayment": "InstantPayment",
      "WireTransfer": "Wire Transfer"
    },
    "paymentMethods": {
      "backupCard": "Backup card",
      "emptyState": {
        "backupCard": "No backup card as a payment method",
        "mainCard": "No main card as a payment method",
        "noneCards": "No other card as a payment method"
      },
      "expiresOn": "Expira em {month, number, 00}/{year}",
      "mainCard": "Main card",
      "noneCards": "Other cards",
      "title": "Payment Methods"
    },
    "phone": "Phone",
    "print": "Print",
    "providers": {
      "actionBtn": "{action, select, register {Activate} unregister {Deactivate} other {}}",
      "dialog": {
        "content": "This action will {action, select, register {activate} unregister {deactivate} other {}} the feature of ",
        "title": "Do you really want to {action, select, register {activate} unregister {deactivate} other {}} this feature?",
        "warningMessage": "Enable this feature and use it for free during 15 days, you can always contact us through comercial@nfe.io to acquire the best plan that fit your needs"
      },
      "emptyState": {
        "description": "You need to have a provider registered to be able to consult it, for that you need to talk to the sales team.",
        "title": "No providers registered in your account"
      },
      "errorState": {
        "description": "There was an error loading your list of registered providers to your subscription, please try again or contact support to resolve the issue.",
        "title": "There was a failure to load your providers"
      },
      "headers": {
        "name": "Feature"
      },
      "table": {
        "AzHub.Account": "Accounts",
        "AzHub.Billing": "Billing",
        "AzHub.Billing.Order": "Billing - Pre-Invoice",
        "AzHub.Usage": "Dashboard Usage",
        "DFeTech.Company": "Multi-company",
        "DFeTech.ConsumerInvoice": "Issue Consumer Invoice (NFC-e)",
        "DFeTech.Mail": "Email delivery",
        "DFeTech.ProductInvoice": "Issue Product Invoice (NF-e)",
        "DFeTech.ProductInvoice.Inbound": "NF-e distribution",
        "DFeTech.ServiceInvoice": "Issue Service Invoice (NFS-e)",
        "DFeTech.ServiceInvoice.Batch": "Issue Service Invoice (NFS-e) up to 500 invoice per worksheet",
        "DFeTech.TransportationInvoice.Inbound": "CT-e distribution",
        "DFeTech.WebHook": "Webhooks",
        "DFeTech.WebHook.Encryption": "Webhook Encryption",
        "DataTech.Batch": "Get Product Invoice (NF-e) Batch - PDF and XML",
        "DataTech.Batch.ProductInvoice.Pdf": "Get Product Invoice (NF-e) Batch via SEFAZ - PDF",
        "DataTech.Batch.ProductInvoice.Serpro": "Get Product Invoices (NFE-e) Batch via SEFAZ and SERPRO as Backup",
        "DataTech.Batch.ProductInvoice.Serpro.Pdf": "Get Product Invoice (NF-e) Batch via SERPRO - PDF",
        "DataTech.Batch.ProductInvoice.Serpro.Xml": "Get Product Invoice (NF-e) Batch via SERPRO - XML",
        "DataTech.Batch.ProductInvoice.Xml": "Get Product Invoice (NF-e) Batch via SEFAZ - XML",
        "DataTech.LegalEntity": "Get Legal Entity (CNPJ)",
        "DataTech.NaturalPerson": "Get Natural Person (CPF)",
        "DataTech.ProductInvoice": "Get Product Invoice (NF-e)",
        "DataTech.WebHook": "Webhooks for data",
        "status": {
          "Active": "Active",
          "Inactive": "Inactive"
        }
      },
      "text": "In this panel you will see all the features available on our platform and choose which ones you would like to use.",
      "title": "Features",
      "toggleProvider": {
        "error": "Error {action, select, register {activating} unregister {deactivating} other {}} feature.",
        "success": "Successfully {action, select, register {activated} unregister {deactivated} other {}} feature!"
      }
    },
    "refresh": "Refresh",
    "remove": "Remove",
    "save": "Save",
    "saveAndPay": "Save and Pay",
    "saving": "Saving",
    "search": "Search",
    "send": "Send",
    "sending": "Sending",
    "shortcuts": {
      "apikeys": {
        "action": {
          "label": "View access keys"
        },
        "description": "Authenticate calls with your Access Keys.",
        "icon": "key",
        "title": "Access Keys"
      },
      "export": {
        "action": {
          "label": "View exports"
        },
        "description": "Download requested usage reports.",
        "icon": "archive",
        "title": "Exports"
      },
      "providers": {
        "action": {
          "label": "View features"
        },
        "description": "Explore and activate platform features.",
        "icon": "settings_applications",
        "title": "Features"
      },
      "usage": {
        "action": {
          "label": "View usage"
        },
        "description": "Check your subscription's consumption.",
        "icon": "analytics",
        "title": "Usage Summary"
      },
      "users": {
        "action": {
          "label": "View users"
        },
        "description": "View and manage users of your subscription.",
        "icon": "people",
        "title": "Users"
      },
      "webhooks": {
        "action": {
          "label": "View webhooks"
        },
        "description": "Receive event notifications in your application.",
        "icon": "webhook",
        "title": "Webhooks"
      }
    },
    "site": "Site",
    "status": "Status",
    "subscription": {
      "basicInfo": {
        "title": "Basic Info"
      },
      "pageTitle": "Subscription",
      "status": {
        "active": "Active",
        "deleted": "Deleted",
        "inactive": "Inactive",
        "none": "None",
        "pastdue": "PastDue",
        "warned": "Warned"
      },
      "statusLabel": "Subscription status",
      "title": "Subscription"
    },
    "subscriptions": {
      "activate": "Activate subscription",
      "apikeys": {
        "title": "Access keys"
      },
      "billable": "Billable",
      "changeTo": {
        "Development": "Change to Development",
        "Production": "Change to Production"
      },
      "createdOn": "Created on",
      "deactivate": "Deactivate subscription",
      "emptyState": {
        "description": "You need to have a subscription registered to be able to consult it, for that you need to talk to the sales team.",
        "title": "No subscription registered in your account"
      },
      "environment": "Environment",
      "errorState": {
        "description": "There was an error loading your list of registered subscriptions to your account, please try again or contact support to resolve the issue.",
        "title": "There was a failure to load your subscriptions"
      },
      "id": "ID",
      "providers": {
        "title": "Providers"
      },
      "spendingLimit": "Spending limit",
      "spendingLimits": {
        "Active": "Active",
        "Inactive": "Inactive",
        "InactiveInPeriod": "Inactive in period"
      },
      "status": {
        "Active": "Active",
        "Cancelled": "Cancelled",
        "Draft": "Draft",
        "Expired": "Expired",
        "Inactive": "Inactive",
        "Suspended": "Suspended"
      },
      "subtitle": "Subscription {status} in {environment} environment",
      "table": {
        "account": "Account",
        "agreement": "Agreement"
      },
      "title": "Subscriptions",
      "usage": {
        "title": "Usage summary"
      },
      "users": {
        "title": "Users"
      },
      "view": {
        "title": "Overview"
      },
      "webhooks": {
        "title": "Webhooks"
      }
    },
    "talkToSales": "Talk to Sales",
    "talkToSupport": "Talk to Support",
    "taxesLabels": {
      "federalTaxNumber": "Federal Tax Number",
      "formalName": "Formal Name",
      "municipalTaxNumber": "Municipal Tax Number",
      "taxRegime": "Tax Regime"
    },
    "tradeName": "Trade Name",
    "true": "Yes",
    "updateToken": "Refreshing access data",
    "usageSummary": {
      "actions": {
        "DFeTech.ConsumerInvoice.ConsumerInvoice.CancelInvoice": "Consumer Invoice (NFC-e) - Send for Cancellation",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.CheckAuthorizationBatch": "Consumer Invoice (NFC-e) - Invoice Status Query",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.DequeueIssue": "Consumer Invoice (NFC-e) - Exit Queue for Issuance",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.EnqueueCancel": "Consumer Invoice (NFC-e) - Cancellation Queue Entry",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.EnqueueCorrectionLetter": "Consumer Invoice (NFC-e) - Correction Queue Entry",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.EnqueueIssue": "Consumer Invoice (NFC-e) - Issuance Queue Entry",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.GenerateDanfe": "Consumer Invoice (NFC-e) - Download DANFE",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.GenerateRejectionXml": "Consumer Invoice (NFC-e) - Download XML - Rejected Invoice",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.GenerateXml": "Consumer Invoice (NFC-e) - Download XML",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.GetEvents": "Consumer Invoice (NFC-e) - Invoice Event Query",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.GetItems": "Consumer Invoice (NFC-e) - Invoice Item Query",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.GetOne": "Consumer Invoice (NFC-e) - Invoice Query",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.MergeAuthorizationProtocol": "Consumer Invoice (NFC-e) - Issuance",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.MergeCancelProtocol": "Consumer Invoice (NFC-e) - Cancellation",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.Push": "Consumer Invoice (NFC-e) - Processing Completion Notification (Webhook)",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.SeekNextSerialNumber": "Consumer Invoice (NFC-e) - Sequential Number Generation",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.SendAuthorizationBatch": "Consumer Invoice (NFC-e) - Send for Issuance",
        "DFeTech.DFeTaxPaymentForm.TaxPaymentForm.Generated": "DARE / GNRE - Issuance",
        "DFeTech.Distribution.Company.Disable": "Distribution (DF-e) - Disable Company for Use",
        "DFeTech.Distribution.Company.Enable": "Distribution (DF-e) - Enable Company for Use",
        "DFeTech.Distribution.Company.GetOne": "Distribution (DF-e) - Query Company Data",
        "DFeTech.Distribution.Company.Update": "Distribution (DF-e) - Update Company Data",
        "DFeTech.Distribution.ProductInvoiceInbound.GetDoc": "Distribution (DF-e) - Summary Query JSON",
        "DFeTech.Distribution.ProductInvoiceInbound.GetDocByKey": "Distribution (DF-e) - Query by Access Key XML",
        "DFeTech.Distribution.ProductInvoiceInbound.GetDocByNsu": "Distribution (DF-e) - Query by Unique Sequential XML ",
        "DFeTech.Distribution.ProductInvoiceInbound.GetEvent": "Distribution (DF-e) - Event Summary Query JSON",
        "DFeTech.Distribution.ProductInvoiceInbound.GetNextBatchDocs": "Distribution (DF-e) - Batch Query",
        "DFeTech.Distribution.ProductInvoiceInbound.GetPdf": "Distribution (DF-e) - Download Authorized PDF",
        "DFeTech.Distribution.ProductInvoiceInbound.GetXml": "Distribution (DF-e) - Download Authorized XML",
        "DFeTech.Distribution.ProductInvoiceInbound.ProcessDoc": "Distribution (DF-e) - Invoice Processing",
        "DFeTech.Distribution.ProductInvoiceInbound.ProcessEvent": "Distribution (DF-e) - Invoice Event Processing",
        "DFeTech.Distribution.ProductInvoiceInbound.ProcessEventSummary": "Distribution (DF-e) - Event Summary Processing",
        "DFeTech.Distribution.ProductInvoiceInbound.ProcessManifest": "Distribution (DF-e) - Operation Acknowledgment Manifest",
        "DFeTech.Distribution.ProductInvoiceInbound.ProcessSummary": "Distribution (DF-e) - Invoice Summary Processing",
        "DFeTech.Distribution.TransportationInvoiceInbound.GetDoc": "Transportation Document (CT-e) - Summary Query JSON",
        "DFeTech.Distribution.TransportationInvoiceInbound.GetDocByKey": "Transportation Document (CT-e) - Query by Access Key XML",
        "DFeTech.Distribution.TransportationInvoiceInbound.GetDocByNsu": "Transportation Document (CT-e) - Query by Unique Sequential XML",
        "DFeTech.Distribution.TransportationInvoiceInbound.GetEvent": "Transportation Document (CT-e) - Event Summary Query JSON",
        "DFeTech.Distribution.TransportationInvoiceInbound.GetNextBatchDocs": "Transportation Document (CT-e) - Batch Query",
        "DFeTech.Distribution.TransportationInvoiceInbound.GetPdf": "Transportation Document (CT-e) - Download Authorized PDF",
        "DFeTech.Distribution.TransportationInvoiceInbound.GetXml": "Transportation Document (CT-e) - Download Authorized XML",
        "DFeTech.Distribution.TransportationInvoiceInbound.ProcessDoc": "Transportation Document (CT-e) - Invoice Processing",
        "DFeTech.Distribution.TransportationInvoiceInbound.ProcessEvent": "Transportation Document (CT-e) - Invoice Event Processing",
        "DFeTech.Distribution.TransportationInvoiceInbound.ProcessEventSummary": "Transportation Document (CT-e) - Event Summary Processing",
        "DFeTech.Distribution.TransportationInvoiceInbound.ProcessManifest": "Transportation Document (CT-e) - Operation Acknowledgment Manifest",
        "DFeTech.Distribution.TransportationInvoiceInbound.ProcessSummary": "Transportation Document (CT-e) - Invoice Summary Processing",
        "DFeTech.ProductInvoice.ProductInvoice.CancelInvoice": "Product Invoices (NF-e) - Send for Cancellation",
        "DFeTech.ProductInvoice.ProductInvoice.CheckAuthorizationBatch": "Product Invoices (NF-e) - Invoice Status Query",
        "DFeTech.ProductInvoice.ProductInvoice.CheckAutorizationBatch": "Product Invoices (NF-e) - Invoice Status Query",
        "DFeTech.ProductInvoice.ProductInvoice.CorrectionLetterInvoice": "Product Invoices (NF-e) - Correction Letter Sent",
        "DFeTech.ProductInvoice.ProductInvoice.DequeueIssue": "Product Invoices (NF-e) - Exit Queue for Issuance",
        "DFeTech.ProductInvoice.ProductInvoice.DisableInvoice": "Product Invoices (NF-e) - Specific Invoice Number Cancellation",
        "DFeTech.ProductInvoice.ProductInvoice.DisableNumbers": "Product Invoices (NF-e) - Invoice Range Cancellation",
        "DFeTech.ProductInvoice.ProductInvoice.EnqueueCancel": "Product Invoices (NF-e) - Cancellation Queue Entry",
        "DFeTech.ProductInvoice.ProductInvoice.EnqueueCorrectionLetter": "Product Invoices (NF-e) - Correction Letter Queue Entry",
        "DFeTech.ProductInvoice.ProductInvoice.EnqueueDisable": "Product Invoices (NF-e) - Invoice Cancellation Queue Entry",
        "DFeTech.ProductInvoice.ProductInvoice.EnqueueIssue": "Product Invoices (NF-e) - Issuance Queue Entry",
        "DFeTech.ProductInvoice.ProductInvoice.GenerateCorrectionLetterDanfe": "Product Invoices (NF-e) - Download PDF - Correction Letter",
        "DFeTech.ProductInvoice.ProductInvoice.GenerateCorrectionLetterXml": "Product Invoices (NF-e) - Download XML - Correction Letter",
        "DFeTech.ProductInvoice.ProductInvoice.GenerateDanfe": "Product Invoices (NF-e) - Download PDF",
        "DFeTech.ProductInvoice.ProductInvoice.GenerateRejectionXml": "Product Invoices (NF-e) - Download XML - Rejected Invoice",
        "DFeTech.ProductInvoice.ProductInvoice.GenerateXml": "Product Invoices (NF-e) - Download XML",
        "DFeTech.ProductInvoice.ProductInvoice.GetEvents": "Product Invoices (NF-e) - Invoice Event Query",
        "DFeTech.ProductInvoice.ProductInvoice.GetItems": "Product Invoices (NF-e) - Invoice Item Query",
        "DFeTech.ProductInvoice.ProductInvoice.GetOne": "Product Invoices (NF-e) - Invoice Query",
        "DFeTech.ProductInvoice.ProductInvoice.MergeAuthorizationProtocol": "Product Invoices (NF-e) - Issuance",
        "DFeTech.ProductInvoice.ProductInvoice.MergeAuthorizationProtocolContingency": "Product Invoices (NF-e) - Issuance in Contingency",
        "DFeTech.ProductInvoice.ProductInvoice.MergeCancelProtocol": "Product Invoices (NF-e) - Cancellation",
        "DFeTech.ProductInvoice.ProductInvoice.MergeCorrectionLetterProtocol": "Product Invoices (NF-e) - Correction Letter Issuance",
        "DFeTech.ProductInvoice.ProductInvoice.Push": "Product Invoices (NF-e) - Processing Completion Notification (Webhook)",
        "DFeTech.ProductInvoice.ProductInvoice.RollBack": "Product Invoices (NF-e) - Deactivation of Automatic Sequential Number Generation",
        "DFeTech.ProductInvoice.ProductInvoice.SeekNextSerialNumber": "Product Invoices (NF-e) - Sequential Number Generation",
        "DFeTech.ProductInvoice.ProductInvoice.SendAuthorizationBatch": "Product Invoices (NF-e) - Send for Issuance",
        "DFeTech.ProductInvoice.ProductInvoice.SendAuthorizationContingency": "Product Invoices (NF-e) - Send for Contingency Issuance",
        "DFeTech.ServiceInvoice.Company.All": "Service Invoices (NFS-e) - Issuer Companies Listing",
        "DFeTech.ServiceInvoice.Company.Create": "Service Invoices (NFS-e) - Create Issuer Company",
        "DFeTech.ServiceInvoice.Company.Delete": "Service Invoices (NFS-e) - Delete Issuer Company",
        "DFeTech.ServiceInvoice.Company.GetOne": "Service Invoices (NFS-e) - Company Identification Listing",
        "DFeTech.ServiceInvoice.Company.ListAll": "Service Invoices (NFS-e) - Company Listing",
        "DFeTech.ServiceInvoice.Company.SetEnvironment": "Service Invoices (NFS-e) - Update Issuer Environment",
        "DFeTech.ServiceInvoice.Company.Update": "Service Invoices (NFS-e) - Update Issuer Data",
        "DFeTech.ServiceInvoice.Company.UploadCertificate": "Service Invoices (NFS-e) - Update Issuer Digital Certificate",
        "DFeTech.ServiceInvoice.Notification.Create": "Service Invoices (NFS-e) - Recipient - Enable Notification",
        "DFeTech.ServiceInvoice.Notification.Delete": "Service Invoices (NFS-e) - Recipient - Disable Notification",
        "DFeTech.ServiceInvoice.ServiceInvoice.CalculateTaxes": "Service Invoices (NFS-e) - Tax Calculation",
        "DFeTech.ServiceInvoice.ServiceInvoice.Cancel": "Service Invoices (NFS-e) - Awaiting Cancellation",
        "DFeTech.ServiceInvoice.ServiceInvoice.Cancelled": "Service Invoices (NFS-e) - Cancellation",
        "DFeTech.ServiceInvoice.ServiceInvoice.CheckBatch": "Service Invoices (NFS-e) - Awaiting Issuance - City Hall",
        "DFeTech.ServiceInvoice.ServiceInvoice.CheckBatchCancel": "Service Invoices (NFS-e) - Awaiting Cancellation - City Hall",
        "DFeTech.ServiceInvoice.ServiceInvoice.Create": "Service Invoices (NFS-e) - Created Invoice",
        "DFeTech.ServiceInvoice.ServiceInvoice.Download": "Service Invoices (NFS-e) - Download",
        "DFeTech.ServiceInvoice.ServiceInvoice.GetOne": "Service Invoices (NFS-e) - Recipient Query",
        "DFeTech.ServiceInvoice.ServiceInvoice.Issued": "Service Invoices (NFS-e) - Issuance",
        "DFeTech.ServiceInvoice.ServiceInvoice.ListAll": "Service Invoices (NFS-e) - Recipient - Company Listing",
        "DFeTech.ServiceInvoice.ServiceInvoice.PushToWebhooks": "Service Invoices (NFS-e) - Processing Completion Notification (Webhook)",
        "DFeTech.ServiceInvoice.ServiceInvoice.QueueCancel": "Service Invoices (NFS-e) - Awaiting Cancellation Submission",
        "DFeTech.ServiceInvoice.ServiceInvoice.QueueIssue": "Service Invoices (NFS-e) - Awaiting Issuance",
        "DFeTech.ServiceInvoice.ServiceInvoice.QueueRetry": "Service Invoices (NFS-e) - Awaiting Reprocessing",
        "DFeTech.ServiceInvoice.ServiceInvoice.SendBorrowerEmail": "Service Invoices (NFS-e) - Email with PDF",
        "DFeTech.ServiceInvoice.ServiceInvoice.Sent": "Service Invoices (NFS-e) - Awaiting Issuance Submission",
        "DFeTech.ServiceInvoice.ServiceInvoice.Update": "Service Invoices (NFS-e) - Update Recipient",
        "DFeTech.Taxes.TaxEngine.Calculate": "Tax Calculation - Product Invoice",
        "DFeTech.Taxes.TaxEngine.CalculateItem": "Tax Calculation - Product Invoice Item",
        "DFeTech.Taxes.TaxEngine.SystaxCalculateItem": "Tax Calculation - Product Invoice Item - Extended Base",
        "DFeTech.Taxes.TaxEngine.SystaxCalculateItemOnDemand": "Tax Calculation - Product Invoice Item - On Demand",
        "DFeTech.Taxes.TaxProduct.Create": "Tax Rule - Product Registration",
        "DFeTech.Taxes.TaxProduct.CreateRule": "Tax Rule - Registered Product Rule Generation",
        "DFeTech.Taxes.TaxProduct.TaxUpdate": "Tax Rule - Product and Tax Rules Update",
        "DFeTech.Taxes.TaxProduct.Update": "Tax Rule - Product Update",
        "DFeTech.Taxes.TaxProduct.UpdateRule": "Tax Rule - Rule Generation for Updated Product",
        "DFeTech.WebHook.ConsumerInvoice.Push": "Notification (Webhook) - NFC-e Processing Completion",
        "DFeTech.WebHook.ProductInvoice.Push": "Notification (Webhook) - NF-e Processing Completion",
        "DFeTech.WebHook.ProductInvoiceInbound.SendWebhook": "Notification (Webhook) - DF-e Processing Completion",
        "DFeTech.WebHook.ServiceInvoice.Create": "Notification (Webhook) - Create Notification",
        "DFeTech.WebHook.ServiceInvoice.Delete": "Notification (Webhook) - Delete Notification",
        "DFeTech.WebHook.ServiceInvoice.GetOne": "Notification (Webhook) - Notification Query",
        "DFeTech.WebHook.ServiceInvoice.ListAll": "Notification (Webhook) - List Notifications",
        "DFeTech.WebHook.ServiceInvoice.PushToWebhooks": "Notification (Webhook) - NFS-e Processing Completion",
        "DFeTech.WebHook.ServiceInvoice.Update": "Notification (Webhook) - Update Notification",
        "DataTech.LegalEntity.LegalEntity.GetBasicInfo": "Company Query - Federal Revenue Office - Basic Data",
        "DataTech.LegalEntity.LegalEntity.GetStateTaxInfo": "Company Query - Sintegra - State Tax Registration",
        "DataTech.LegalEntity.LegalEntity.GetStateTaxInfoWebService": "Company Query - Sintegra - State Tax Registration (Web Service)",
        "DataTech.LegalEntity.LegalEntity.GetTaxRegime": "Company Query - Simples Nacional - Basic Data",
        "DataTech.LegalEntity.LegalEntityV2.GetBasicInfo": "Company Query - Federal Revenue Office - Basic Data - version 2.1",
        "DataTech.LegalEntity.LegalEntityV2.GetStateTaxInfo": "Company Query - Sintegra - State Tax Registration - version 2.1",
        "DataTech.LegalEntity.LegalEntityV2.GetStateTaxSuggestedForInvoice": "Company Query - Sintegra - Suggested State Tax Registration for Invoice Issuance 2.0",
        "DataTech.LegalEntity.StateTaxV2.GetStateTaxForInvoice": "Company Query - Sintegra - State Tax Registration for Invoice Issuance",
        "DataTech.LegalEntity.StateTaxV2.GetStateTaxInfo": "Company Query - Sintegra - State Tax Registration - version 2",
        "DataTech.LegalEntity.StateTaxV2.GetStateTaxSuggestedForInvoice": "Company Query - Sintegra - Suggested State Tax Registration for Invoice Issuance",
        "DataTech.NaturalPerson.NaturalPerson.GetAddress": "Person Query - Federal Revenue Office - Address Data",
        "DataTech.NaturalPerson.NaturalPerson.GetComplete": "Person Query - Federal Revenue Office - Complete Data",
        "DataTech.NaturalPerson.NaturalPerson.GetExists": "Person Query - Validity and Existence",
        "DataTech.NaturalPerson.NaturalPerson.GetStatus": "Person Query - Federal Revenue Office - Basic Data",
        "DataTech.ProductInvoice.ProductInvoice.GetInvoice": "Invoice Query - SEFAZ - JSON",
        "DataTech.ProductInvoice.ProductInvoice.GetInvoiceEvents": "Invoice Query - SEFAZ - Invoice Event JSON",
        "DataTech.ProductInvoice.ProductInvoice.GetInvoicePdf": "Invoice Query - SEFAZ - DANFE",
        "DataTech.ProductInvoice.ProductInvoice.GetInvoiceXml": "Invoice Query - SEFAZ - XML",
        "OneWebhook": "Conta > Consulta de um WebHook",
        "ProductInvoicesV2": "Nota Fiscal Eletrônica (v2)",
        "SERPRO.LegalEntity.LegalEntity.GetBasicInfo": "Company Query - SERPRO - Basic Data",
        "SERPRO.ProductInvoice.ProductInvoice.GetSerproInvoice": "Invoice Query - SERPRO - JSON",
        "SERPRO.ProductInvoice.ProductInvoice.GetSerproInvoicePdf": "Invoice Query - SERPRO - DANFE",
        "SERPRO.ProductInvoice.ProductInvoice.GetSerproInvoiceXml": "Invoice Query - SERPRO - XML",
        "UpdateWebhook": "Conta > Atualização de WebHook"
      },
      "dateRange": "Month/Year",
      "emptyState": {
        "description": "You must have used our platform and some usage record has been created. If in doubt, contact support.",
        "title": "No usage record found"
      },
      "errorState": {
        "description": "There was an error loading the usage summary, please try again or contact support to resolve the issue.",
        "title": "Failed to load the usage summary"
      },
      "exportAction": "Export usage of current period",
      "exportLabel": "Export",
      "headers": {
        "action": "Action",
        "error": "Error",
        "notFound": "Not Found",
        "product": "Product",
        "resource": "Resource",
        "success": "Success",
        "total": "Total Used",
        "totalBillable": "Total Billable"
      },
      "lastUpdatedOn": "Updated at {updatedOn, date, medium}",
      "newExport": "Export process started!",
      "providers": {
        "ConsumerInvoice": "Nota Fiscal do Consumidor Eletrônica (NFC-e)",
        "DFeTech.ConsumerInvoice": "Nota Fiscal do Consumidor Eletrônica (NFC-e)",
        "DFeTech.DFeTaxPaymentForm": "DARE / GNRE",
        "DFeTech.Distribution": "Conhecimento de Transporte (CT-e)",
        "DFeTech.ProductInvoice": "Notas Fiscais de Produtos (NF-e)",
        "DFeTech.ServiceInvoice": "Notas Fiscais de Serviço (NFS-e)",
        "DFeTech.Taxes": "Regra Tributária",
        "DFeTech.WebHook": "Notificação (WebHook)",
        "DataTech.ConsumerInvoice": "Consulta de Nota Fiscal de Consumidor (NFC-e)",
        "DataTech.LegalEntity": "Consulta Empresa",
        "DataTech.NaturalPerson": "Consulta Pessoa",
        "DataTech.ProductInvoice": "Consulta NFE - SEFAZ",
        "DataTech.WebHook": "Notificação (WebHook)",
        "LegalEntity": "Consulta de Pessoa Jurídica (CNPJ)",
        "LegalEntityV2": "Consulta Empresa",
        "NaturalPerson": "Consulta Pessoa",
        "ProductInvoice": "Notas Fiscais de Produtos (NF-e)",
        "SERPRO.LegalEntity": "Consulta Empresa",
        "SERPRO.ProductInvoice": "Consulta NFE - SERPRO",
        "ServiceInvoice": "Notas Fiscais de Serviço (NFS-e)",
        "StateTaxV2": "Consulta Empresa"
      },
      "resources": {
        "Company": "Empresas",
        "ConsumerInvoice": "Nota Fiscal do Consumidor Eletrônica (NFC-e)",
        "LegalEntity": "Consulta Empresa",
        "LegalEntityV2": "Consulta Empresa",
        "NaturalPerson": "Consulta Pessoa",
        "Notification": "Notificações",
        "ProductInvoice": "Notas Fiscais de Produtos (NF-e)",
        "ProductInvoiceInbound": "Distribuição (DF-e)",
        "ServiceInvoice": "Notas Fiscais de Serviço (NFS-e)",
        "StateTaxV2": "Consulta Empresa",
        "TaxEngine": "Cálculo de Imposto",
        "TaxPaymentForm": "DARE / GNRE",
        "TaxProduct": "Regra Tributária",
        "TransportationInvoiceInbound": "Conhecimento de Transporte (CT-e)"
      }
    },
    "userSettings": {
      "errorState": {
        "laoding": "Error loading settings"
      }
    },
    "users": {
      "createBtn": "Add User",
      "delete": {
        "error": "Falha ao excluir o usuário",
        "success": "User "
      },
      "dialog": {
        "confirmBtn": "Remove",
        "content": "This action will delete the user ",
        "title": "Do you really want to delete this user?"
      },
      "emptySearch": {
        "description": "Try different terms. If you still can’t find the desired user, please contact support for assistance.",
        "title": "No user found"
      },
      "emptyState": {
        "description": "You need to have a user registered in your subscription to consult it.",
        "title": "No users registered in your account"
      },
      "errorState": {
        "description": "There was an error loading your list of registered users to your subscription, please try again or contact support to resolve the issue.",
        "title": "There was a failure to load your users"
      },
      "headers": {
        "user": "User"
      },
      "new": {
        "confirmation": {
          "error": "Fail to send invite",
          "label": "Confirmation",
          "sending": "Sending...",
          "success": "Invite sent with success!",
          "userIsNotAdmin": "Only the account admin can add users",
          "userWithoutAccount": "User does not have an account"
        },
        "defineUser": {
          "addUserBtn": "Send invite",
          "label": "New user email"
        },
        "title": "Add User"
      },
      "table": {
        "buttons": {
          "deleteUser": "Delete"
        },
        "emptyList": {
          "text": "Only the account admin can edit and delete users, contact your account admin and request the change you need to be done.",
          "title": "Feature exclusive for the account admin"
        },
        "tooltips": {
          "deleteUser": "Delete user"
        }
      },
      "text": "Access this panel to see all users with access to your account. Here you can add or remove users.",
      "title": "Users"
    },
    "viewing": "Viewing",
    "webhooks": {
      "buttons": {
        "delete": "Delete",
        "edit": "Edit"
      },
      "data": "Webhook Data",
      "delete": {
        "error": "Error removing webhook",
        "success": "Webhook successfully removed!"
      },
      "edit": {
        "dialog": {
          "confirmBtn": "Delete",
          "header": "Delete this Webhook?",
          "text": "<p>This operation will delete the webhook: </p><p><strong>{uri}</strong></p><br /><p><strong>Are you sure you want to continue?</strong></p>"
        },
        "success": "Webhook successfully changed!",
        "title": "Webhook information"
      },
      "empty": {
        "available": "All available events have been selected",
        "selected": "No events were selected"
      },
      "emptyList": {
        "text": "Webhooks are responsible for monitoring and reporting on events generated by our platform, for example when an invoice has been issued or canceled.",
        "title": "No webhook found"
      },
      "error": {
        "accessibleUrl": "Unable to validate URL. Make sure the URL is accessible.",
        "alreadyExists": "Webhook already exists",
        "clientIdNotFound": "The Client ID was not found at the authority server.",
        "default": "Unable to test your url. Our test expects your server to return an http status between 200 and 299.",
        "invalidClientId": "Client ID: Please enter a valid Client ID.",
        "invalidSchema": "The WebHook URI must be absolute with a scheme of either 'http' or 'https'",
        "invalidUri": "Invalid URI: The hostname could not be parsed.",
        "validUrl": "Please ensure that the WebHook URI is valid and that the endpoint is accessible."
      },
      "errorState": {
        "description": "There was an error loading your list of registered webhooks to your subscription, please try again or contact support to resolve the issue.",
        "title": "There was a failure to load your webhooks"
      },
      "events": {
        "eventsOptions": {
          "cancel": "Cancelada",
          "issue": "Emitida",
          "pull": "Extraída"
        },
        "title": "Eventos monitorados"
      },
      "filters": {
        "consumer_invoice-cancelled_error": "Cancelled error",
        "consumer_invoice-cancelled_failed": "Cancelled failed",
        "consumer_invoice-cancelled_successfully": "Cancelled successfully",
        "consumer_invoice-issued_error": "Issued Error",
        "consumer_invoice-issued_failed": "Issued failed",
        "consumer_invoice-issued_successfully": "Issued successfully",
        "product_invoice-cancelled_error": "Cancelled error",
        "product_invoice-cancelled_failed": "Cancelled failed",
        "product_invoice-cancelled_successfully": "Cancelled successfully",
        "product_invoice-cce_error": "CCE error",
        "product_invoice-cce_failed": "CCE failed",
        "product_invoice-cce_successfully": "CCE successfully",
        "product_invoice-issued_error": "Issued Error",
        "product_invoice-issued_failed": "Issued failed",
        "product_invoice-issued_successfully": "Issued successfully",
        "product_invoice_inbound-event_raised_successfully": "NF-e event generated successfully",
        "product_invoice_inbound-issued_successfully": "Issued successfully",
        "product_invoice_inbound_summary-event_raised_successfully": "NF-e summary event generated successfully",
        "product_invoice_inbound_summary-issued_successfully": "NF-e summary issued successfully",
        "product_tax-created_successfully": "Created successfully",
        "product_tax-creation_failed": "Creation failed",
        "product_tax-custom_rules_requested": "Custom rules requested",
        "service_invoice-cancelled_error": "Cancelled error",
        "service_invoice-cancelled_failed": "Cancelled failed",
        "service_invoice-cancelled_successfully": "Cancelled successfully",
        "service_invoice-issued_error": "Issued Error",
        "service_invoice-issued_failed": "Issued failed",
        "service_invoice-issued_successfully": "Issued successfully",
        "service_invoice-pulled": "Pulled",
        "tax_payment_form-created_successfully": "Tax successfully",
        "tax_payment_form-creation_failed": "Tax failed",
        "tax_payment_form-creation_not_needed": "Tax not needed",
        "transportation_invoice_inbound-event_raised_successfully": "CT-e event generated successfully",
        "transportation_invoice_inbound-issued_successfully": "Issued successfully"
      },
      "headers": {
        "events": "Events",
        "modifiedOn": "Modified on",
        "status": "Status",
        "url": "URL"
      },
      "labels": {
        "active": "Active",
        "available": "Available",
        "inactive": "Inactive",
        "insecureSsl": "Insecure SSL",
        "providerDisabled": "Provider disabled",
        "secret": "Pasword for message authentication (HMAC)",
        "secureSsl": "Verify SSL",
        "selected": "Selected {numEvents, plural, =0 {} =1 {(# event)} other {(# events)}}",
        "status": "Is active?",
        "url": "Payload URL"
      },
      "new": {
        "error": "Error creating webhook",
        "fillFields": "fill in the webhook data",
        "selectType": "Select webhook type",
        "success": "Webhook successfully registered!",
        "title": "Create Webhook"
      },
      "title": "Webhooks",
      "type": {
        "consumer_invoice": "NFC-e issuance",
        "product_invoice": "NF-e issuance",
        "product_invoice_inbound": "NF-e distribution",
        "product_tax": "Product",
        "service_invoice": "NFS-e issuance",
        "tax_payment_form": "GNRE",
        "transportation_invoice_inbound": "CT-e distribution"
      },
      "types": {
        "title": "Tipo de webhook"
      }
    },
    "yes": "Yes"
  }], ["pt-br", {
    "account": {
      "basicInfo": {
        "title": "Detalhes"
      },
      "chargeInfo": {
        "title": "Cobrança"
      },
      "contact": {
        "action": {
          "edit": "Editar contato"
        },
        "administrator": "Administrador",
        "billing": "Cobrança",
        "description": {
          "administrator": "Pessoa responsável pela conta da empresa dentro da NFe.io. Este usuário receberá notificações importantes, como faturas, renovações, atualizações e etc.",
          "billing": "Pessoa responsável pela conta da empresa dentro da NFe.io. Este usuário receberá notificações importantes, como faturas, renovações, atualizações e etc.",
          "technical": "Pessoa responsável pela conta da empresa dentro da NFe.io. Este usuário receberá notificações importantes, como faturas, renovações, atualizações e etc."
        },
        "dialog": {
          "edit": {
            "title": "Editar contato"
          }
        },
        "empty": {
          "description": "Nenhum contato cadastrado, todas notificações serão enviadas somente ao administrador.",
          "title": "Adicione seu contato de {contactType}"
        },
        "subtitle": {
          "administrator": "Responsável administrador",
          "billing": "Responsável pela cobrança",
          "technical": "Responsável Técnico"
        },
        "technical": "Responsável Técnico",
        "title": "Contatos",
        "type": "Tipo"
      },
      "errorMessages": {
        "email": "Informe um email válido",
        "phone": "Insira um número de telefone válido"
      },
      "helperText": {
        "email": {
          "admin": "Para alterar essa informação, entre em contato com o suporte (11) 4063-8091"
        }
      },
      "pageTitle": "Informações da conta",
      "title": "Conta"
    },
    "accounts": {
      "emptyState": {
        "description": "Tente outros termos. Para telefone não use nem o código do país e nem o da região. Para CNPJ use todas as pontuações. Caso não encontre a conta desejada contate o suporte para solucionar o problema.",
        "title": "Nenhuma conta corresponde à pesquisa"
      },
      "errorState": {
        "description": "Tente outros termos. Para telefone não use nem o código do país e nem o da região. Para CNPJ use todas as pontuações. Caso não encontre a conta desejada contate o suporte para solucionar o problema.",
        "title": "Ocorreu uma falha ao pesquisar esse termo"
      },
      "pageTitle": "Contas",
      "title": "Contas",
      "useAs": "Usar como assinatura",
      "useAsSubscriptionId": "Usar como assinatura {id}"
    },
    "actions": "Ações",
    "activate": "Ativar",
    "add": "Adicionar",
    "address": "Endereço",
    "admin": {
      "panelTitle": "Painel Administrativo",
      "title": "Administrador"
    },
    "agreement": {
      "actionActivate": "Reativar",
      "actionOpenTerms": "Termos de Serviço",
      "actionPrintTermsAnnex": "Imprimir Anexo",
      "actionSign": "Contratar",
      "actionSwitch": "Exibir outros contratos",
      "address": {
        "additionalInformation": "Complemento",
        "city": {
          "label": "Cidade",
          "searchPlaceholder": "Pesquise a Cidade"
        },
        "country": {
          "label": "País",
          "selectCountry": "Selecione um país"
        },
        "district": "Bairro",
        "header": "Dados do Endereço",
        "number": "Número",
        "postalCode": "CEP",
        "state": {
          "label": "Estado",
          "searchPlaceholder": "Pesquise o Estado"
        },
        "street": "Logradouro"
      },
      "agreement-print": {
        "availableServices": "Serviços contratados disponíveis",
        "details": {
          "title": "Detalhes do contrato"
        },
        "info": "Este documento está vinculado ao CONDIÇÕES GERAIS DE LICENCIAMENTO DE SOFTWARE E PRESTAÇÃO DE SERVIÇOS.",
        "product": {
          "billCycleType": {
            "ActivationBased": "Na assinatura do contrato",
            "AgreementBased": "Conforme o Contrato",
            "UsageBased": "Conforme Utilização"
          },
          "price": "Valor"
        },
        "title": "Anexo"
      },
      "billingCard": {
        "beginOn": "Data de ínicio",
        "cardTitle": "{brand} Final {number}",
        "cardValidAt": "Expira em {month}/{year}",
        "endOn": "Data de término",
        "frequency": "Periodicidade",
        "indefinite": "Indeterminado",
        "paymentMethods": "Meios de pagamento",
        "preferredPaymentMethod": "Cartão de crédito preferencial",
        "salesTerm": "Condição de pagamento",
        "title": "Cobrança"
      },
      "detailsCard": {
        "beginOn": "Data de ínicio",
        "contractEscalationPriceIndex": "Índice de correção monetária",
        "contractEscalationType": "Forma de aplicação da correção",
        "customTerm": "Termo complementar",
        "duration": "Duração",
        "durationFormat": "{duration} {frequency}",
        "einvoiceDescription": "Descrição para nota fiscal",
        "endOn": "Data de término",
        "goToInvoices": "Ver faturas",
        "indefinite": "Indeterminado",
        "minimumDeductibleAmount": "Valor da franquia",
        "notificationTermination": "Notificação de Rescisão",
        "signedOn": "Data da assinatura do contrato",
        "title": "Detalhes"
      },
      "pageTitle": "Contrato #{number}",
      "productListCard": {
        "filter_all": "Todos",
        "filter_minimumQuantity": "Com Consumo Mínimo",
        "table": {
          "headers": {
            "cycles": "Ciclos",
            "discountCycles": "Ciclos de desconto",
            "minimumQuantity": "Consumo Mínimo",
            "product": "Produto"
          }
        },
        "title": "Produtos do contrato"
      },
      "productPriceRates": {
        "table": {
          "headers": {
            "begin": "Qtd. Início",
            "end": "Qtd. Final",
            "listPrice": "Valor Unitário",
            "overageListPrice": "Valor Excedente"
          }
        },
        "title": "Faixas de Preço do Produto"
      },
      "status": {
        "Active": "Ativo",
        "Cancelled": "Cancelado",
        "Draft": "Rascunho",
        "Expired": "Expirado",
        "Suspended": "Suspenso"
      },
      "statusLabel": "Situação do contrato",
      "subscriptionsCard": {
        "title": "Assinaturas"
      },
      "supportCard": {
        "availableMedia": "Meios de comunicação disponíveis",
        "openingHours": "Horário de atendimento",
        "title": "Suporte"
      },
      "title": "Contrato"
    },
    "amount": "Quantidade",
    "apikeys": {
      "clientId": "Client ID",
      "clients": {
        "title": "Clients e Secrets"
      },
      "emptyState": {
        "description": "Você precisa ter uma assinatura registrada para que possa consulta-las, para isso você precisa falar com o time de vendas.",
        "title": "Nenhuma chave de acesso registrada na sua conta"
      },
      "errorState": {
        "description": "Ocorreu um erro ao carregar sua lista de chaves de acesso registradas a sua conta, tente novamente ou contate o suporte para solucionar o problema.",
        "title": "Ocorreu uma falha ao carregar suas chaves de acesso"
      },
      "group": "Chaves",
      "keys": {
        "title": "Lista de Chaves de Acesso"
      },
      "status": {
        "Active": "Ativa",
        "Inactive": "Inativa"
      },
      "table": {
        "actions": "Ações",
        "createdOn": "Criada em",
        "description": "Descrição",
        "expiration": "Expiração",
        "id": "Chave",
        "key": "Chave",
        "modifiedOn": "Modificada em",
        "status": "Status",
        "type": "Tipo"
      },
      "title": "Chaves de Acesso"
    },
    "bankName": "Banco {name}",
    "cancel": "Cancelar",
    "close": "Fechar",
    "components": {
      "datepicker": {
        "value_invalid": "A data não é valida",
        "value_out-of-range": "A data deve ficar entre '{'min'}' e '{'max'}'"
      }
    },
    "contractEscalationPriceIndex": {
      "IGPM": "IGP-M",
      "IPCA": "IPCA"
    },
    "contractEscalationType": {
      "Addendum": "Mediante Aditivo",
      "Automatically": "Automática"
    },
    "copied": "Copiado!",
    "copy": "Copiar",
    "createAccount": {
      "companyName": "Nome da empresa",
      "contactDescription": "As informações de contato servem para nossa equipe comercial esclarecer e enviar mais detalhes sobre nossos produtos e preços.",
      "contactTitle": "Contato da conta",
      "create": "Criar conta",
      "creating": "Criando conta",
      "infoDescription": "As contas são responsáveis por administrar as empresas, notas fiscais e produtos contratados. Você poderá criar outras contas depois.",
      "infoTitle": "Dados da conta",
      "title": "Criar conta"
    },
    "creditCard": "Cartão de crédito",
    "dataTable": {
      "emptyState": {
        "title": "Nenhum registro encontrado"
      },
      "errorState": {
        "title": "Erro ao carregar registros"
      },
      "headers": {
        "effectiveOn": "Competência",
        "invoice": "Fatura",
        "invoiceTotal": "Total da Fatura"
      },
      "pagination": {
        "pageRange": "{firstItem} - {lastItem} de {total}",
        "perPage": "Linhas por página"
      },
      "preInvoices": "Pré-faturas"
    },
    "days": "Dia(s)",
    "description": "Descrição",
    "drawer": {
      "accountInfo": "Visão geral",
      "aggreement": "Contratos",
      "apikeys": "Chaves de acesso",
      "invoices": "Faturas",
      "paymentMethods": "Métodos de pagamento",
      "preInvoices": "Pré-faturas",
      "subscriptions": "Assinaturas"
    },
    "drawerAdmin": {
      "accounts": "Contas"
    },
    "duration": {
      "Annual": "Anos",
      "Bimonthly": "Bimestres",
      "Monthly": "Meses",
      "Quarterly": "Trimestres",
      "Semester": "Semestres"
    },
    "edit": "Alterar",
    "email": "Email",
    "environments": {
      "development": "Testes",
      "production": "Produção",
      "sandbox": "Testes",
      "test": "Teste"
    },
    "errorMessages": {
      "address": {
        "cityCode": "Código deve conter 7 caracteres",
        "districtOrCounty": "Insira no minimo 2 caracteres",
        "line1": "Insira no minimo 4 caracteres",
        "postalCode": "CEP deve seguir o formato 99999-999"
      },
      "creditCardForm": {
        "default": "Falha inesperada ao cadastrar o seu cartão, entre em contato com o nosso suporte através do telefone (11) 4063-8091",
        "expirationDate": "Insira uma data posterior a hoje",
        "invalidMonth": "Mês de expiração do cartão inválido",
        "invalidName": "O nome do cartão deve contém nome e sobrenome",
        "invalidNumber": "O número do cartão é inválido",
        "invalidSecurityCode": "O código de verificação do cartão é inválido",
        "invalidYear": "Ano de expiração do cartão inválido",
        "name": "Insira o nome igual está no cartão",
        "number": "Insira um número de cartão válido",
        "securityCode": "Insira de 3 a 4 dígitos"
      },
      "default": {
        "federalTaxNumber": "CNPJ inválido",
        "notEmpty": "Não pode ser vazio"
      },
      "subscription": "Assinatura",
      "totalAmount": "Valor Total"
    },
    "export": "Exportar",
    "exports": {
      "emptyState": {
        "description": "Não encontramos nenhuma exportação. Você pode exportar um relatório do uso da sua conta ou um lote de NFS-e nas listagem de notas da sua empresa.",
        "title": "Nenhuma exportação encontrada"
      },
      "emptyTable": {
        "text": "Não encontramos nenhuma exportação. Você pode exportar um relatório do uso da sua conta ou um lote de NFS-e nas listagem de notas da sua empresa.",
        "title": "Nenhuma exportação encontrada"
      },
      "errorState": {
        "description": "Ocorreu um erro ao carregar sua lista de exportações, tente novamente ou contate o suporte para solucionar o problema.",
        "title": "Ocorreu uma falha ao carregar suas exportações"
      },
      "gotToExports": "Ir para exportações",
      "resource": {
        "account-usage": "Uso da conta",
        "company-product-invoice-analytical-csv": "Relatório de NF-e - CSV",
        "company-product-invoice-pdf": "Lote de NF-e - PDF",
        "company-product-invoice-xml": "Lote de NF-e - XML",
        "company-service-invoice-analytical-csv": "Relatório de NFS-e - CSV",
        "company-service-invoice-pdf": "Lote de NFS-e - PDF",
        "company-service-invoice-xml": "Lote de NFS-e - XML"
      },
      "status": {
        "Compressing": "Comprimindo",
        "Done": "Concluído",
        "Exporting": "Exportando",
        "Failed": "Falha",
        "None": "Nenhum",
        "Pending": "Pendente",
        "Running": "Em andamento"
      },
      "table": {
        "actions": {
          "download": "Baixar exportação",
          "title": "Ações"
        },
        "createdOn": "Criado em",
        "date": "Período",
        "timeSpent": "Tempo gasto",
        "totalLines": "N°. de linhas",
        "type": "Tipo"
      },
      "text": "Aqui você verá uma lista com todos os relatórios de uso solicitados e poderá baixá-los.",
      "title": "Exportações"
    },
    "false": "Não",
    "federalTaxNumber": "CPF/CNPJ",
    "frequency": {
      "Annual": "Anual",
      "Bimonthly": "Bimestral",
      "Monthly": "Mensal",
      "Quarterly": "Trimestral",
      "Semester": "Semestral"
    },
    "goBack": "Voltar",
    "goToOld": "Ir para versão antiga",
    "helpCenter": {
      "apisReference": "Referência de APIs",
      "contactUs": "Fale conosco",
      "docs": "Documentação",
      "nfsFirstSteps": "Primeiros passos NFSe",
      "openTicket": "Abrir Ticket",
      "serviceCodeRequest": "Cadastro de código de serviço"
    },
    "id": "ID",
    "identity": {
      "accountManagement": "Gerenciar sua conta de usuário"
    },
    "impersonate": {
      "companion": {
        "action": "Definir como conta atual",
        "description": "Você está <strong>pré-visualizando</strong> os dados desta conta. Deseja definir como a conta atual?"
      }
    },
    "inactivate": "Inativar",
    "invoice": {
      "code": "Código Identificador",
      "dueOn": "Vencimento em",
      "effectiveOn": "Competência em",
      "subscription": "Assinatura",
      "title": "Fatura",
      "total": "Total da Fatura"
    },
    "invoices": {
      "download": {
        "pdf": "Nota fiscal",
        "xml": "Nota fiscal em XML"
      },
      "emptyState": {
        "description": "Você precisa ter uma fatura registrada para que possa consulta-las, para isso você precisa falar com o time de vendas.",
        "title": "Nenhuma fatura registrada na sua conta"
      },
      "errorState": {
        "description": "Ocorreu um erro ao carregar sua lista de faturas registradas a sua conta, tente novamente ou contate o suporte para solucionar o problema.",
        "title": "Ocorreu uma falha ao carregar suas faturas"
      },
      "status": {
        "AwaitingAccounting": "Aguardando contabilidade",
        "AwaitingEInvoice": "Aguardando Nota Fiscal",
        "AwaitingPayment": "Aguardando pagamento",
        "AwaitingPaymentMethod": "Aguardando método de pagamento",
        "Cancelled": "Cancelado",
        "Paid": "Pago",
        "PartiallyPaid": "Parcialmente pago",
        "PaymentDone": "Pagamento feito",
        "Pending": "Pendente",
        "Refunded": "Estornado"
      },
      "title": "Faturas",
      "titleSingular": "Fatura"
    },
    "issuerName": "Emissor {name}",
    "legalName": "Razão Social",
    "logout": "Sair",
    "name": "Nome",
    "needHelp": "Precisa de ajuda?",
    "no": "Não",
    "order": {
      "customerInfo": "Dados de Cliente",
      "merchantInfo": "Dados de Fornecedor",
      "status": {
        "Approved": "Aprovada",
        "Canceled": "Cancelada",
        "Declined": "Reprovada",
        "Done": "Faturada",
        "Draft": "Nova",
        "Executing": "Em andamento",
        "Mistaken": "Cancelada",
        "NotBilled": "Cancelada",
        "Refused": "Recusada",
        "WaitingApproval": "Aguardando",
        "WaitingBilling": "Faturando",
        "unknown": "Desconhecido"
      }
    },
    "orders": {
      "approve": {
        "description": "Deseja realmente aprovar esta Pré-Fatura?",
        "title": "Aprovar Pré-Fatura"
      },
      "emptyState": {
        "description": "Você precisa ter uma pré-fatura registrada para que possa consulta-las, para isso você precisa falar com o time de vendas.",
        "title": "Nenhuma pré-fatura registrada na sua conta"
      },
      "errorState": {
        "description": "Ocorreu um erro ao carregar sua lista de pré-faturas registradas a sua conta, tente novamente ou contate o suporte para solucionar o problema.",
        "title": "Ocorreu uma falha ao carregar suas pré-faturas"
      },
      "pageTitle": "Pré-Faturas #{number}",
      "refuse": {
        "description": "Você está recusando essa pré-fatura, informe o motivo a baixo.",
        "title": "Recusar pré-fatura"
      },
      "status": {
        "Approved": "Aprovada",
        "Declined": "Reprovada",
        "Done": "Faturada",
        "Draft": "Nova",
        "Executing": "Em andamento",
        "NotBilled": "Não faturado",
        "Refused": "Recusada",
        "WaitingApproval": "Aguardando aprovação",
        "WaitingBilling": "Faturando",
        "unknown": "Desconhecido"
      },
      "title": "Pré-Faturas",
      "titleSingular": "Pré-Fatura"
    },
    "pageTitle": "Contas | NFE.io",
    "pay": "Pagar",
    "payment": {
      "code": "Cód. de pagamento",
      "customerInfo": "Dados de Cliente",
      "dialog": {
        "bankSlip": {
          "copy": "Copiar",
          "download": "Baixar",
          "identification": "Linha digitável",
          "text": "Procure em seu aplicativo de banco ou conta digital a opção de pagamento via boleto e copie e cole os números acima.",
          "title": "Pagar via Boleto Bancário"
        },
        "creditCard": {
          "checkCode": "CVC",
          "expirationData": "MM/AA",
          "name": "Nome (igual no cartão)",
          "number": "Número do cartão",
          "title": "Novo cartão de crédito"
        },
        "error": {
          "creditCardData": "Por favor, verifique seus dados ou entre em contato com sua operadora de cartão.",
          "statusPending": "A fatura cobrada precisa estar pendente.",
          "title": "Pagamento recusado",
          "unavailable": "Nosso sistema de pagamentos está indisponível. Entre em contato com o nosso suporte (11) 4063-8091."
        },
        "instantPayment": {
          "copy": "Copiar",
          "identification": "Pix Copia e Cola",
          "text": "Procure em seu aplicativo de banco ou conta digital a opção de pagamento via PIX e escaneie o QR Code acima.",
          "title": "Pagamento por PIX"
        },
        "paymentMethods": {
          "errorMessages": {
            "expiredOn": "Expirado em"
          },
          "expiresOn": "Expira em",
          "new": "Adicionar Cartão de Crédito",
          "title": "Selecionar forma de pagamento",
          "type": {
            "BankSlip": "Boleto",
            "CreditCard": "Cartão de crédito",
            "InstantPayment": "PIX",
            "WireTransfer": "Transferência bancária"
          }
        },
        "running": "Processando pagamento",
        "runningBankSlip": "Gerando boleto",
        "runningPix": "Gerando PIX",
        "success": {
          "title": "Obrigado por seu pagamento",
          "waitingRelease": "Seu pagamento foi autorizado e em breve receberemos a confirmação."
        },
        "wireTransfer": {
          "fields": {
            "accountNumber": "Conta corrente",
            "bank": "Banco",
            "bankBranchCode": "Agência",
            "identityNumber": "CNPJ"
          },
          "title": "Transferência bancária"
        }
      },
      "discount": "Desconto",
      "heading": "Pagamento",
      "merchantInfo": "Dados de Fornecedor",
      "paidAmount": "Valor pago",
      "paidOn": "Pago em",
      "paymentMethod": "Forma de pagamento",
      "penalty": "Multa/Juros",
      "status": {
        "Cancelled": "Cancelado",
        "DivergentPaid": "Divergente pago",
        "Expired": "Expirado",
        "New": "Novo",
        "Paid": "Pago",
        "PartiallyPaid": "Parcialmente pago",
        "Pending": "Pendente",
        "Refunded": "Estornado",
        "WaitingGateway": "Aguardando Gateway"
      },
      "statusLabel": "Situação do pagamento",
      "subtotal": "Valor do Subtotal",
      "taxWithheld": "Imposto Retido",
      "title": "Pagamento #{number}",
      "total": "Valor Total"
    },
    "paymentMethodTypes": {
      "BankSlip": "Boleto",
      "CreditCard": "Cartão de crédito",
      "DigitalWallet": "Carteira Digital",
      "InstantPayment": "PIX",
      "WireTransfer": "Transferência bancária"
    },
    "paymentMethods": {
      "backupCard": "Cartão secundário",
      "emptyState": {
        "backupCard": "Nenhum cartão cadastrado como secundário",
        "mainCard": "Nenhum cartão cadastrado como principal",
        "noneCards": "Nenhum outro cartão de crédito cadastrado"
      },
      "expiresOn": "Expira em {month, number, ::sign-never 00}/{year}",
      "mainCard": "Cartão principal",
      "noneCards": "Outros cartões",
      "title": "Métodos de Pagamentos"
    },
    "phone": "Telefone",
    "print": "Imprimir",
    "providers": {
      "actionBtn": "{action, select, register {Ativar} unregister {Inativar} other {}}",
      "dialog": {
        "content": "Esta ação irá {action, select, register {ativar} unregister {inativar} other {}} a funcionalidade de ",
        "title": "Deseja realmente {action, select, register {ativar} unregister {inativar} other {}} esta funcionalidade?",
        "warningMessage": "Ao ativar esse serviço você poderá utilizar gratuitamente por 15 dias, após esse periodo poderá gerar cobranças"
      },
      "emptyState": {
        "description": "Você precisa ter uma funcionalidade registrada para que possa utilizar a plataforma, para isso você precisa falar com o time de vendas.",
        "title": "Nenhuma funcionalidade registrada na sua conta"
      },
      "errorState": {
        "description": "Ocorreu um erro ao carregar sua lista de funcionalidades registradas a sua conta, tente novamente ou contate o suporte para solucionar o problema.",
        "title": "Ocorreu uma falha ao carregar suas funcionalidades"
      },
      "headers": {
        "name": "Funcionalidade"
      },
      "table": {
        "AzHub.Account": "Contas",
        "AzHub.Billing": "Cobrança",
        "AzHub.Billing.Order": "Cobrança - Pré-Fatura",
        "AzHub.Usage": "Uso da Platataforma",
        "DFeTech.Company": "Múltiplas Empresas",
        "DFeTech.ConsumerInvoice": "Emissão de Nota Fiscal de Consumidor (NFC-e)",
        "DFeTech.Mail": "Envio de E-mail",
        "DFeTech.ProductInvoice": "Emissão de Nota Fiscal de Produto (NF-e)",
        "DFeTech.ProductInvoice.Inbound": "NF-e distribuição",
        "DFeTech.ServiceInvoice": "Emissão de Nota Fiscal de Serviço (NFS-e)",
        "DFeTech.ServiceInvoice.Batch": "Emissão de até 500 Notas Fiscais de Serviço (NFS-e) em Lote pela plataforma",
        "DFeTech.TransportationInvoice.Inbound": "CT-e distribuição",
        "DFeTech.WebHook": "Webhooks para Emissão de Notas",
        "DFeTech.WebHook.Encryption": "Encripitação de Webhook",
        "DataTech.Batch": "Consulta de Notas Fiscais de Produto (NF-e) em Lote - DANFE e XML",
        "DataTech.Batch.ProductInvoice.Pdf": "Consulta de Notas Fiscais de Produto (NF-e) em Lote via SEFAZ - DANFE",
        "DataTech.Batch.ProductInvoice.Serpro": "Consulta de Notas Fiscais de Produto (NFE-e) em Lote via SEFAZ e SERPRO como Backup",
        "DataTech.Batch.ProductInvoice.Serpro.Pdf": "Consulta de Notas Fiscais de Produto (NF-e) em Lote via SERPRO - DANFE",
        "DataTech.Batch.ProductInvoice.Serpro.Xml": "Consulta de Notas Fiscais de Produto (NF-e) em Lote via SERPRO - XML",
        "DataTech.Batch.ProductInvoice.Xml": "Consulta de Notas Fiscais de Produto (NF-e) em Lote via SEFAZ - XML",
        "DataTech.LegalEntity": "Consulta de CNPJ",
        "DataTech.NaturalPerson": "Consulta de CPF",
        "DataTech.ProductInvoice": "Consulta de Notas Fiscais de Produto (NF-e)",
        "DataTech.WebHook": "Webhooks para Consulta de dados",
        "status": {
          "Active": "Ativo",
          "Inactive": "Inativo"
        }
      },
      "text": "Nesse painel você verá todas as funcionalidades disponíveis em nossa plataforma e escolher quais gostaria de utilizar.",
      "title": "Funcionalidades",
      "toggleProvider": {
        "error": "Erro ao {action, select, register {ativar} unregister {inativar} other {}} funcionalidade.",
        "success": "Funcionalidade {action, select, register {ativada} unregister {desativada} other {}} com sucesso!"
      }
    },
    "refresh": "Atualizar",
    "remove": "Remover",
    "save": "Salvar",
    "saveAndPay": "Salvar e Pagar",
    "saving": "Salvando",
    "search": "Pesquisar",
    "send": "Enviar",
    "sending": "Enviando",
    "shortcuts": {
      "apikeys": {
        "action": {
          "label": "Ver chaves de acesso"
        },
        "description": "Autentique chamadas com suas Chaves de Acesso.",
        "icon": "key",
        "title": "Chaves de Acesso"
      },
      "export": {
        "action": {
          "label": "Ver exportações"
        },
        "description": "Baixe relatórios de uso solicitados.",
        "icon": "archive",
        "title": "Exportações"
      },
      "providers": {
        "action": {
          "label": "Ver funcionalidades"
        },
        "description": "Explore e ative funcionalidades da plataforma.",
        "icon": "settings_applications",
        "title": "Funcionalidades"
      },
      "usage": {
        "action": {
          "label": "Ver uso"
        },
        "description": "Confira o consumo da sua assinatura.",
        "icon": "analytics",
        "title": "Resumo de Utilização"
      },
      "users": {
        "action": {
          "label": "Ver usuários"
        },
        "description": "Visualize e gerencie usuários da sua assinatura.",
        "icon": "people",
        "title": "Usuários"
      },
      "webhooks": {
        "action": {
          "label": "Ver webhooks"
        },
        "description": "Receba notificações de eventos em sua aplicação.",
        "icon": "webhook",
        "title": "Webhooks"
      }
    },
    "site": "Site",
    "status": "Status",
    "subscription": {
      "basicInfo": {
        "title": "Detalhes"
      },
      "pageTitle": "Informações da assinatura",
      "status": {
        "active": "Ativa",
        "deleted": "Deletada",
        "inactive": "Inativa",
        "none": "Indefinido",
        "pastdue": "Vencida",
        "warned": "Avisada"
      },
      "statusLabel": "Situação da assinatura",
      "title": "Assinatura"
    },
    "subscriptions": {
      "activate": "Ativar assinatura",
      "apikeys": {
        "title": "Chaves de Acesso"
      },
      "billable": "Faturável",
      "changeTo": {
        "Development": "Mudar para Desenvolvimento",
        "Production": "Mudar para Produção"
      },
      "createdOn": "Criado em",
      "deactivate": "Desativar assinatura",
      "emptyState": {
        "description": "Você precisa ter uma assinatura registrada para que possa consulta-las, para isso você precisa falar com o time de vendas.",
        "title": "Nenhuma assinatura registrada na sua conta"
      },
      "environment": "Ambiente",
      "errorState": {
        "description": "Ocorreu um erro ao carregar sua lista de assinaturas registradas a sua conta, tente novamente ou contate o suporte para solucionar o problema.",
        "title": "Ocorreu uma falha ao carregar suas assinaturas"
      },
      "id": "ID",
      "providers": {
        "title": "Funcionalidades"
      },
      "spendingLimit": "Limite de despesas",
      "spendingLimits": {
        "Active": "Ativo",
        "Inactive": "Inativo",
        "InactiveInPeriod": "Inativo no período"
      },
      "status": {
        "Active": "Ativa",
        "Cancelled": "Cancelada",
        "Draft": "Rascunho",
        "Expired": "Expirada",
        "Inactive": "Inativa",
        "Suspended": "Suspensa"
      },
      "subtitle": "Assinatura {status} em {environment}",
      "table": {
        "account": "Conta",
        "agreement": "Contrato"
      },
      "title": "Assinaturas",
      "usage": {
        "title": "Resumo de utilização"
      },
      "users": {
        "title": "Usuários"
      },
      "view": {
        "title": "Visão geral"
      },
      "webhooks": {
        "title": "Webhooks"
      }
    },
    "talkToSales": "Falar com comercial",
    "talkToSupport": "Falar com suporte",
    "taxesLabels": {
      "federalTaxNumber": "CNPJ",
      "formalName": "Razão Social",
      "municipalTaxNumber": "Inscrição Municipal",
      "taxRegime": "Regime Tributário"
    },
    "tradeName": "Nome Fantasia",
    "true": "Sim",
    "updateToken": "Atualizando dados de acesso",
    "usageSummary": {
      "actions": {
        "DFeTech.ConsumerInvoice.ConsumerInvoice.CancelInvoice": "Nota Fiscal do Consumidor (NFC-e) - Envio para Cancelamento",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.CheckAuthorizationBatch": "Nota Fiscal do Consumidor (NFC-e) - Consulta da Situação da Nota",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.DequeueIssue": "Nota Fiscal do Consumidor (NFC-e) - Saída da Fila para Emissão",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.EnqueueCancel": "Nota Fiscal do Consumidor (NFC-e) - Entrada na Fila para Cancelamento",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.EnqueueCorrectionLetter": "Nota Fiscal do Consumidor (NFC-e) - Entrada na Fila para Correção",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.EnqueueIssue": "Nota Fiscal do Consumidor (NFC-e) - Entrada na Fila para Emissão",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.GenerateDanfe": "Nota Fiscal do Consumidor (NFC-e) - Download DANFE",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.GenerateRejectionXml": "Nota Fiscal do Consumidor (NFC-e) - Download XML - Nota Rejeitada",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.GenerateXml": "Nota Fiscal do Consumidor (NFC-e) - Download XML",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.GetEvents": "Nota Fiscal do Consumidor (NFC-e) - Consulta de Eventos da Nota",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.GetItems": "Nota Fiscal do Consumidor (NFC-e) - Consulta de Itens da Nota",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.GetOne": "Nota Fiscal do Consumidor (NFC-e) - Consulta Nota",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.MergeAuthorizationProtocol": "Nota Fiscal do Consumidor (NFC-e) - Emissão",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.MergeCancelProtocol": "Nota Fiscal do Consumidor (NFC-e) - Cancelamento",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.Push": "Nota Fiscal do Consumidor (NFC-e) - Notificação de Conclusão do Processamento (Webhook)",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.SeekNextSerialNumber": "Nota Fiscal do Consumidor (NFC-e) - Geração de Número Sequencial",
        "DFeTech.ConsumerInvoice.ConsumerInvoice.SendAuthorizationBatch": "Nota Fiscal do Consumidor (NFC-e) - Envio para Emissão",
        "DFeTech.DFeTaxPaymentForm.TaxPaymentForm.Generated": "DARE / GNRE - Emissão",
        "DFeTech.Distribution.Company.Disable": "Distribuição (DF-e) Desabilita Empresa para Utilização",
        "DFeTech.Distribution.Company.Enable": "Distribuição (DF-e) Habilida Empresa para Utilização",
        "DFeTech.Distribution.Company.GetOne": "Distribuição (DF-e) Consulta Dados da Empresa",
        "DFeTech.Distribution.Company.Update": "Distribuição (DF-e) Atualiza Dados da Empresa",
        "DFeTech.Distribution.ProductInvoiceInbound.GetDoc": "Distribuição (DF-e) Consulta do Resumo JSON",
        "DFeTech.Distribution.ProductInvoiceInbound.GetDocByKey": "Distribuição (DF-e) Consulta por Chave de Acesso XML",
        "DFeTech.Distribution.ProductInvoiceInbound.GetDocByNsu": "Distribuição (DF-e) Consulta por Sequencial Único XML",
        "DFeTech.Distribution.ProductInvoiceInbound.GetEvent": "Distribuição (DF-e) Consulta do Resumo do Evento  JSON",
        "DFeTech.Distribution.ProductInvoiceInbound.GetNextBatchDocs": "Distribuição (DF-e) Consulta do Lote",
        "DFeTech.Distribution.ProductInvoiceInbound.GetPdf": "Distribuição (DF-e) Download PDF Autorizado",
        "DFeTech.Distribution.ProductInvoiceInbound.GetXml": "Distribuição (DF-e) Download XML Autorizado",
        "DFeTech.Distribution.ProductInvoiceInbound.ProcessDoc": "Distribuição (DF-e) Processamento da Nota",
        "DFeTech.Distribution.ProductInvoiceInbound.ProcessEvent": "Distribuição (DF-e) Processamento do Evento da Nota",
        "DFeTech.Distribution.ProductInvoiceInbound.ProcessEventSummary": "Distribuição (DF-e) Processamento do Resumo do Evento da Nota",
        "DFeTech.Distribution.ProductInvoiceInbound.ProcessManifest": "Distribuição (DF-e) Manifestação da Ciência da Operação",
        "DFeTech.Distribution.ProductInvoiceInbound.ProcessSummary": "Distribuição (DF-e) Processamento do Resumo da Nota",
        "DFeTech.Distribution.TransportationInvoiceInbound.GetDoc": "Conhecimento de Transporte (CT-e) Consulta do Resumo JSON",
        "DFeTech.Distribution.TransportationInvoiceInbound.GetDocByKey": "Conhecimento de Transporte (CT-e) Consulta por Chave de Acesso XML",
        "DFeTech.Distribution.TransportationInvoiceInbound.GetDocByNsu": "Conhecimento de Transporte (CT-e) Consulta por Sequencial Único XML",
        "DFeTech.Distribution.TransportationInvoiceInbound.GetEvent": "Conhecimento de Transporte (CT-e) Consulta do Resumo do Evento  JSON",
        "DFeTech.Distribution.TransportationInvoiceInbound.GetNextBatchDocs": "Conhecimento de Transporte (CT-e) Consulta do Lote",
        "DFeTech.Distribution.TransportationInvoiceInbound.GetPdf": "Conhecimento de Transporte (CT-e) Download Autorizado PDF",
        "DFeTech.Distribution.TransportationInvoiceInbound.GetXml": "Conhecimento de Transporte (CT-e) Download Autorizado XML",
        "DFeTech.Distribution.TransportationInvoiceInbound.ProcessDoc": "Conhecimento de Transporte (CT-e) Processamento da Nota",
        "DFeTech.Distribution.TransportationInvoiceInbound.ProcessEvent": "Conhecimento de Transporte (CT-e) Processamento do Evento da Nota",
        "DFeTech.Distribution.TransportationInvoiceInbound.ProcessEventSummary": "Conhecimento de Transporte (CT-e) Processamento do Resumo do Evento da Nota",
        "DFeTech.Distribution.TransportationInvoiceInbound.ProcessManifest": "Conhecimento de Transporte (CT-e) Manifestação da Ciência da Operação",
        "DFeTech.Distribution.TransportationInvoiceInbound.ProcessSummary": "Conhecimento de Transporte (CT-e) Processamento do Resumo da Nota",
        "DFeTech.ProductInvoice.ProductInvoice.CancelInvoice": "Notas Fiscais de Produto (NF-e) - Envio para Cancelamento",
        "DFeTech.ProductInvoice.ProductInvoice.CheckAuthorizationBatch": "Notas Fiscais de Produto (NF-e) - Consulta Situação da Nota",
        "DFeTech.ProductInvoice.ProductInvoice.CheckAutorizationBatch": "Notas Fiscais de Produto (NF-e) - Consulta Situação da Nota",
        "DFeTech.ProductInvoice.ProductInvoice.CorrectionLetterInvoice": "Notas Fiscais de Produto (NF-e) - Carta de Correção Enviada",
        "DFeTech.ProductInvoice.ProductInvoice.DequeueIssue": "Notas Fiscais de Produto (NF-e) - Saída da Fila para Emissão",
        "DFeTech.ProductInvoice.ProductInvoice.DisableInvoice": "Notas Fiscais de Produto (NF-e) - Inutilização de Números de NF específico",
        "DFeTech.ProductInvoice.ProductInvoice.DisableNumbers": "Notas Fiscais de Produto (NF-e) - Inutilização de Range de Números de NF",
        "DFeTech.ProductInvoice.ProductInvoice.EnqueueCancel": "Notas Fiscais de Produto (NF-e) - Entrada na Fila para Cancelamento",
        "DFeTech.ProductInvoice.ProductInvoice.EnqueueCorrectionLetter": "Notas Fiscais de Produto (NF-e) - Entrada na Fila para Carta de Correção",
        "DFeTech.ProductInvoice.ProductInvoice.EnqueueDisable": "Notas Fiscais de Produto (NF-e) - Entrada na Fila para Inutilização de Nota",
        "DFeTech.ProductInvoice.ProductInvoice.EnqueueIssue": "Notas Fiscais de Produto (NF-e) - Entrada da Fila para Emissão",
        "DFeTech.ProductInvoice.ProductInvoice.GenerateCorrectionLetterDanfe": "Notas Fiscais de Produto (NF-e) - Download PDF - Carta de Correção",
        "DFeTech.ProductInvoice.ProductInvoice.GenerateCorrectionLetterXml": "Notas Fiscais de Produto (NF-e) - Download XML - Carta de Correção",
        "DFeTech.ProductInvoice.ProductInvoice.GenerateDanfe": "Notas Fiscais de Produto (NF-e) - Download PDF",
        "DFeTech.ProductInvoice.ProductInvoice.GenerateRejectionXml": "Notas Fiscais de Produto (NF-e) - Download XML - Nota Rejeitada",
        "DFeTech.ProductInvoice.ProductInvoice.GenerateXml": "Notas Fiscais de Produto (NF-e) - Download XML",
        "DFeTech.ProductInvoice.ProductInvoice.GetEvents": "Notas Fiscais de Produto (NF-e) - Consulta Eventos da Nota",
        "DFeTech.ProductInvoice.ProductInvoice.GetItems": "Notas Fiscais de Produto (NF-e) - Consulta de Itens da Nota",
        "DFeTech.ProductInvoice.ProductInvoice.GetOne": "Notas Fiscais de Produto (NF-e) - Consulta Nota",
        "DFeTech.ProductInvoice.ProductInvoice.MergeAuthorizationProtocol": "Notas Fiscais de Produto (NF-e) - Emissão",
        "DFeTech.ProductInvoice.ProductInvoice.MergeAuthorizationProtocolContingency": "Notas Fiscais de Produto (NF-e) - Emissão em Contingência",
        "DFeTech.ProductInvoice.ProductInvoice.MergeCancelProtocol": "Notas Fiscais de Produto (NF-e) - Cancelamento",
        "DFeTech.ProductInvoice.ProductInvoice.MergeCorrectionLetterProtocol": "Notas Fiscais de Produto (NF-e) - Emissão da Carta de Correção",
        "DFeTech.ProductInvoice.ProductInvoice.Push": "Notas Fiscais de Produto (NF-e) - Notificação de Conclusão do Processamento (Webhook)",
        "DFeTech.ProductInvoice.ProductInvoice.RollBack": "Notas Fiscais de Produto (NF-e) - Desativação da Geração de Número Sequencial Automático",
        "DFeTech.ProductInvoice.ProductInvoice.SeekNextSerialNumber": "Notas Fiscais de Produto (NF-e) - Geração de Número Sequencial",
        "DFeTech.ProductInvoice.ProductInvoice.SendAuthorizationBatch": "Notas Fiscais de Produto (NF-e) - Envio para Emissão",
        "DFeTech.ProductInvoice.ProductInvoice.SendAuthorizationContingency": "Notas Fiscais de Produto (NF-e) - Envio para Emissão em Contigência",
        "DFeTech.ServiceInvoice.Company.All": "Notas Fiscais de Serviço (NFS-e) - Listagem de Empresas Emitentes",
        "DFeTech.ServiceInvoice.Company.Create": "Notas Fiscais de Serviço (NFS-e) - Criação de Empresa Emissora",
        "DFeTech.ServiceInvoice.Company.Delete": "Notas Fiscais de Serviço (NFS-e) - Deleção de Empresa Emissora",
        "DFeTech.ServiceInvoice.Company.GetOne": "Notas Fiscais de Serviço (NFS-e) - Listagem de empresas por identificação",
        "DFeTech.ServiceInvoice.Company.ListAll": "Notas Fiscais de Serviço (NFS-e) - Listagem de Empresas",
        "DFeTech.ServiceInvoice.Company.SetEnvironment": "Notas Fiscais de Serviço (NFS-e) - Atualização do Ambiente do Emissor",
        "DFeTech.ServiceInvoice.Company.Update": "Notas Fiscais de Serviço (NFS-e) - Atualização de Dados do Emissor",
        "DFeTech.ServiceInvoice.Company.UploadCertificate": "Notas Fiscais de Serviço (NFS-e) - Atualização do Certificado Digital do Emissor",
        "DFeTech.ServiceInvoice.Notification.Create": "Notas Fiscais de Serviço (NFS-e) - Tomador - Habilita Notificação",
        "DFeTech.ServiceInvoice.Notification.Delete": "Notas Fiscais de Serviço (NFS-e) - Tomador - Desabilita Notificação",
        "DFeTech.ServiceInvoice.ServiceInvoice.CalculateTaxes": "Notas Fiscais de Serviço (NFS-e) - Cálculo de Imposto",
        "DFeTech.ServiceInvoice.ServiceInvoice.Cancel": "Notas Fiscais de Serviço (NFS-e) - Aguardando Cancelamento",
        "DFeTech.ServiceInvoice.ServiceInvoice.Cancelled": "Notas Fiscais de Serviço (NFS-e) - Cancelamento",
        "DFeTech.ServiceInvoice.ServiceInvoice.CheckBatch": "Notas Fiscais de Serviço (NFS-e) - Aguardando Emissão - Prefeitura",
        "DFeTech.ServiceInvoice.ServiceInvoice.CheckBatchCancel": "Notas Fiscais de Serviço (NFS-e) - Aguardando Cancelamento - Prefeitura",
        "DFeTech.ServiceInvoice.ServiceInvoice.Create": "Notas Fiscais de Serviço (NFS-e) - Nota Criada",
        "DFeTech.ServiceInvoice.ServiceInvoice.Download": "Notas Fiscais de Serviço (NFS-e) - Download",
        "DFeTech.ServiceInvoice.ServiceInvoice.GetOne": "Notas Fiscais de Serviço (NFS-e) - Consulta do Tomador",
        "DFeTech.ServiceInvoice.ServiceInvoice.Issued": "Notas Fiscais de Serviço (NFS-e) - Emissão",
        "DFeTech.ServiceInvoice.ServiceInvoice.ListAll": "Notas Fiscais de Serviço (NFS-e) - Tomador - Listagem de Empresas",
        "DFeTech.ServiceInvoice.ServiceInvoice.PushToWebhooks": "Notas Fiscais de Serviço (NFS-e) - Notificação de Conclusão do Processamento (Webhook)",
        "DFeTech.ServiceInvoice.ServiceInvoice.QueueCancel": "Notas Fiscais de Serviço (NFS-e) - Aguardando Envio para Cancelamento",
        "DFeTech.ServiceInvoice.ServiceInvoice.QueueIssue": "Notas Fiscais de Serviço (NFS-e) - Aguardando Emissão",
        "DFeTech.ServiceInvoice.ServiceInvoice.QueueRetry": "Notas Fiscais de Serviço (NFS-e) - Aguardando Reprocessamento",
        "DFeTech.ServiceInvoice.ServiceInvoice.SendBorrowerEmail": "Notas Fiscais de Serviço (NFS-e) - Envio de e-mail com PDF",
        "DFeTech.ServiceInvoice.ServiceInvoice.Sent": "Notas Fiscais de Serviço (NFS-e) - Aguardando Envio para Emissão",
        "DFeTech.ServiceInvoice.ServiceInvoice.Update": "Notas Fiscais de Serviço (NFS-e) - Atualização do Tomador",
        "DFeTech.Taxes.TaxEngine.Calculate": "Cálculo de Imposto - NF de Produtos",
        "DFeTech.Taxes.TaxEngine.CalculateItem": "Cálculo de Imposto - Item da NF de Produtos",
        "DFeTech.Taxes.TaxEngine.SystaxCalculateItem": "Cálculo de Imposto - Item da NF de Produtos - Base Extendida",
        "DFeTech.Taxes.TaxEngine.SystaxCalculateItemOnDemand": "Cálculo de Imposto - Item da NF de Produtos - On Demand",
        "DFeTech.Taxes.TaxProduct.Create": "Regra Tributária - Cadastro de Produto",
        "DFeTech.Taxes.TaxProduct.CreateRule": "Regra Tributária - Geração de Regras do Produto Cadastrado",
        "DFeTech.Taxes.TaxProduct.TaxUpdate": "Regra Tributária - Atualização do Produto e Regras Tributárias",
        "DFeTech.Taxes.TaxProduct.Update": "Regra Tributária - Atualização do Produto",
        "DFeTech.Taxes.TaxProduct.UpdateRule": "Regra Tributária - Geração de Regras do Produto Atualizado",
        "DFeTech.WebHook.ConsumerInvoice.Push": "Notificação (WebHook) - Conclusão do Processamento da NFC-e",
        "DFeTech.WebHook.ProductInvoice.Push": "Notificação (WebHook) - Conclusão do Processamento da NF-e",
        "DFeTech.WebHook.ProductInvoiceInbound.SendWebhook": "Notificação (WebHook) - Conclusão do Processamento da DF-e",
        "DFeTech.WebHook.ServiceInvoice.Create": "Notificação (WebHook) - Criação de Notificação",
        "DFeTech.WebHook.ServiceInvoice.Delete": "Notificação (WebHook) - Exclusão de Notificação",
        "DFeTech.WebHook.ServiceInvoice.GetOne": "Notificação (WebHook) - Consulta de Notificação",
        "DFeTech.WebHook.ServiceInvoice.ListAll": "Notificação (WebHook) - Listar Notificações",
        "DFeTech.WebHook.ServiceInvoice.PushToWebhooks": "Notificação (WebHook) - Conclusão do Processamento da NFS-e",
        "DFeTech.WebHook.ServiceInvoice.Update": "Notificação (WebHook) - Atualização da Notificação",
        "DataTech.LegalEntity.LegalEntity.GetBasicInfo": "Consulta Empresa - Receita Federal - Dados Básicos",
        "DataTech.LegalEntity.LegalEntity.GetStateTaxInfo": "Consulta Empresa - Sintegra - Inscrição Estadual",
        "DataTech.LegalEntity.LegalEntity.GetStateTaxInfoWebService": "Consulta Empresa - Sintegra - Inscrição Estadual (Web Service)",
        "DataTech.LegalEntity.LegalEntity.GetTaxRegime": "Consulta Empresa - Simples Nacional - Dados Básicos",
        "DataTech.LegalEntity.LegalEntityV2.GetBasicInfo": "Consulta Empresa - Receita Federal - Dados Básicos - versão 2.1",
        "DataTech.LegalEntity.LegalEntityV2.GetStateTaxInfo": "Consulta Empresa - Sintegra - Inscrição Estadual - versão 2.1",
        "DataTech.LegalEntity.LegalEntityV2.GetStateTaxSuggestedForInvoice": "Consulta Empresa - Sintegra - Inscrição Estadual Sugerida p/ emissão de NFE 2.0",
        "DataTech.LegalEntity.StateTaxV2.GetStateTaxForInvoice": "Consulta Empresa - Sintegra - Inscrição Estadual p/ emissão de NFE",
        "DataTech.LegalEntity.StateTaxV2.GetStateTaxInfo": "Consulta Empresa - Sintegra - Inscrição Estadual - versão 2",
        "DataTech.LegalEntity.StateTaxV2.GetStateTaxSuggestedForInvoice": "Consulta Empresa - Sintegra - Inscrição Estadual Sugerida p/ emissão de NFE",
        "DataTech.NaturalPerson.NaturalPerson.GetAddress": "Consulta Pessoa - Receita Federal - Dados do Endereço",
        "DataTech.NaturalPerson.NaturalPerson.GetComplete": "Consulta Pessoa - Receita Federal - Dados Completos",
        "DataTech.NaturalPerson.NaturalPerson.GetExists": "Consulta Pessoa - Validade e Existência",
        "DataTech.NaturalPerson.NaturalPerson.GetStatus": "Consulta Pessoa - Receita Federal - Dados Básicos",
        "DataTech.ProductInvoice.ProductInvoice.GetInvoice": "Consulta NFE - SEFAZ - JSON",
        "DataTech.ProductInvoice.ProductInvoice.GetInvoiceEvents": "Consulta NFE - SEFAZ - Eventos da Nota JSON",
        "DataTech.ProductInvoice.ProductInvoice.GetInvoicePdf": "Consulta NFE - SEFAZ - DANFE",
        "DataTech.ProductInvoice.ProductInvoice.GetInvoiceXml": "Consulta NFE - SEFAZ - XML",
        "OneWebhook": "Conta > Consulta de um WebHook",
        "ProductInvoicesV2": "Nota Fiscal Eletrônica (v2)",
        "SERPRO.LegalEntity.LegalEntity.GetBasicInfo": "Consulta Empresa - SERPRO - Dados Básicos",
        "SERPRO.ProductInvoice.ProductInvoice.GetSerproInvoice": "Consulta NFE - SERPRO - JSON",
        "SERPRO.ProductInvoice.ProductInvoice.GetSerproInvoicePdf": "Consulta NFE - SERPRO - DANFE",
        "SERPRO.ProductInvoice.ProductInvoice.GetSerproInvoiceXml": "Consulta NFE - SERPRO - XML",
        "UpdateWebhook": "Conta > Atualização de WebHook"
      },
      "dateRange": "Mês/Ano",
      "emptyState": {
        "description": "Você precisa ter utilizado nossa plataforma algum registro de utilização tenha sido criado. Em caso de dúvidas contate o suporte.",
        "title": "Nenhuma utilização foi registrada"
      },
      "errorState": {
        "description": "Ocorreu um erro ao carregar o resumo de utilização, tente novamente ou contate o suporte para solucionar o problema.",
        "title": "Ocorreu uma falha ao carregar seu resumo"
      },
      "exportAction": "Exportar utilização do período atual",
      "exportLabel": "Exportar",
      "headers": {
        "action": "Ação",
        "error": "Erro",
        "notFound": "Não encontrado",
        "product": "Produto",
        "resource": "Recurso",
        "success": "Sucesso",
        "total": "Total Consumido",
        "totalBillable": "Total Faturável"
      },
      "lastUpdatedOn": "Atualizado em {updatedOn, date, medium}",
      "newExport": "Processo de exportação em andamento!",
      "providers": {
        "ConsumerInvoice": "Nota Fiscal do Consumidor Eletrônica (NFC-e)",
        "DFeTech.ConsumerInvoice": "Nota Fiscal do Consumidor Eletrônica (NFC-e)",
        "DFeTech.DFeTaxPaymentForm": "DARE / GNRE",
        "DFeTech.Distribution": "Conhecimento de Transporte (CT-e)",
        "DFeTech.ProductInvoice": "Notas Fiscais de Produtos (NF-e)",
        "DFeTech.ServiceInvoice": "Notas Fiscais de Serviço (NFS-e)",
        "DFeTech.Taxes": "Regra Tributária",
        "DFeTech.WebHook": "Notificação (WebHook)",
        "DataTech.ConsumerInvoice": "Consulta de Nota Fiscal de Consumidor (NFC-e)",
        "DataTech.LegalEntity": "Consulta Empresa",
        "DataTech.NaturalPerson": "Consulta Pessoa",
        "DataTech.ProductInvoice": "Consulta NFE - SEFAZ",
        "DataTech.WebHook": "Notificação (WebHook)",
        "LegalEntity": "Consulta Empresa",
        "LegalEntityV2": "Consulta Empresa",
        "NaturalPerson": "Consulta Pessoa",
        "ProductInvoice": "Notas Fiscais de Produtos (NF-e)",
        "SERPRO.LegalEntity": "Consulta Empresa",
        "SERPRO.ProductInvoice": "Consulta NFE - SERPRO",
        "ServiceInvoice": "Notas Fiscais de Serviço (NFS-e)",
        "StateTaxV2": "Consulta Empresa"
      },
      "resources": {
        "Company": "Empresas",
        "ConsumerInvoice": "Nota Fiscal do Consumidor Eletrônica (NFC-e)",
        "LegalEntity": "Consulta Empresa",
        "LegalEntityV2": "Consulta Empresa",
        "NaturalPerson": "Consulta Pessoa",
        "Notification": "Notificações",
        "ProductInvoice": "Notas Fiscais de Produtos (NF-e)",
        "ProductInvoiceInbound": "Distribuição (DF-e)",
        "ServiceInvoice": "Notas Fiscais de Serviço (NFS-e)",
        "StateTaxV2": "Consulta Empresa",
        "TaxEngine": "Cálculo de Imposto",
        "TaxPaymentForm": "DARE / GNRE",
        "TaxProduct": "Regra Tributária",
        "TransportationInvoiceInbound": "Conhecimento de Transporte (CT-e)"
      }
    },
    "userSettings": {
      "errorState": {
        "laoding": "Erro ao carregar informações"
      }
    },
    "users": {
      "createBtn": "Adicionar Usuário",
      "delete": {
        "error": "Falha ao remover o usuário",
        "success": "Usuário removido com sucesso"
      },
      "dialog": {
        "confirmBtn": "Remover",
        "content": "Esta ação irá remover o usuário ",
        "title": "Você tem certeza que deseja remover este usuário?"
      },
      "emptySearch": {
        "description": "Tente outros termos. Caso não encontre o usuário desejado contate o suporte para solucionar o problema.",
        "title": "Nenhum usuário foi encontrado"
      },
      "emptyState": {
        "description": "Você precisa ter um usuário registrado na sua assinatura para consultá-lo.",
        "title": "Nenhum usuário registrado na sua conta"
      },
      "errorState": {
        "description": "Ocorreu um erro ao carregar sua lista de usuários registradas a sua conta, tente novamente ou contate o suporte para solucionar o problema.",
        "title": "Ocorreu uma falha ao carregar seus usuários"
      },
      "headers": {
        "user": "Usuário"
      },
      "new": {
        "confirmation": {
          "error": "Falha ao enviar o convite",
          "label": "Confirmação de envio",
          "sending": "Enviando convite...",
          "success": "Convite enviado com sucesso!",
          "userIsNotAdmin": "Somente o administrador da conta pode adicionar usuários",
          "userWithoutAccount": "Usuário não tem vínculo com uma conta"
        },
        "defineUser": {
          "addUserBtn": "Enviar convite",
          "label": "Email do novo usuário"
        },
        "title": "Adicionar usuário"
      },
      "table": {
        "buttons": {
          "deleteUser": "Remover"
        },
        "emptyList": {
          "text": "Somente o administrador da conta pode criar e remover usuários, entre em contato com o administrador da sua conta e solicite a alteração que você deseja.",
          "title": "Recurso exclusivo para o Administrador da conta"
        },
        "tooltips": {
          "deleteUser": "Remover usuário"
        }
      },
      "text": "Acesse esse painel para ver todos os usuários que tem acesso a sua conta. Aqui você poderá adicionar e remover usuários.",
      "title": "Usuários"
    },
    "viewing": "Visualizando",
    "webhooks": {
      "buttons": {
        "delete": "Excluir",
        "edit": "Alterar"
      },
      "data": "Dados do webhook",
      "delete": {
        "error": "Erro ao excluir webhook",
        "success": "Webhook excluído com sucesso!"
      },
      "edit": {
        "dialog": {
          "confirmBtn": "Excluir",
          "header": "Excluir Webhook?",
          "text": "<p>Esta operação irá excluir definitivamente o webhook: </p><p><strong>{uri}</strong></p><br /><p><strong>Você tem certeza que deseja continuar?</strong></p>"
        },
        "success": "Webhook alterado com sucesso!",
        "title": "Informações do Webhook"
      },
      "empty": {
        "available": "Todos os eventos disponíveis foram selecionados",
        "selected": "Nenhum evento foi selecionado"
      },
      "emptyList": {
        "text": "Os Webhooks são responsáveis por monitorar e notificar sobre os eventos gerados por nossa plataforma, por exemplo quando uma nota fiscal foi emitida ou cancelada.",
        "title": "Nenhum webhook encontrado"
      },
      "error": {
        "accessibleUrl": "Não foi possível validar a URL. Verifique se a URL é acessível.",
        "alreadyExists": "Webhook já existe",
        "clientIdNotFound": "O Client ID não foi encontrado no servidor de autoridade.",
        "default": "Não conseguimos testar a sua url. Nosso teste espera que o seu servidor retorne um status http entre 200 e 299.",
        "invalidClientId": "Client ID: Informe um Client ID válido.",
        "invalidSchema": "A URL do Webhook deve ser absoluta com um esquema de 'http' ou 'https'",
        "invalidUri": "URL inválida: O nome do host não pôde ser analisado.",
        "validUrl": "Por favor verifique se a URL é válida e que o endpoint é acessível."
      },
      "errorState": {
        "description": "Ocorreu um erro ao carregar sua lista de webhooks registradas na sua assinatura, tente novamente ou contate o suporte para solucionar o problema.",
        "title": "Ocorreu uma falha ao carregar seus webhooks"
      },
      "events": {
        "eventsOptions": {
          "cancel": "Cancelada",
          "issue": "Emitida",
          "pull": "Extraída"
        },
        "title": "Quais eventos você gostaria de monitorar?"
      },
      "filters": {
        "consumer_invoice-cancelled_error": "Erro no cancelamento",
        "consumer_invoice-cancelled_failed": "Falha no cancelamento",
        "consumer_invoice-cancelled_successfully": "Cancelada com sucesso",
        "consumer_invoice-issued_error": "Erro na emissão",
        "consumer_invoice-issued_failed": "Falha na emissão",
        "consumer_invoice-issued_successfully": "Emitida com sucesso",
        "product_invoice-cancelled_error": "Erro no cancelamento",
        "product_invoice-cancelled_failed": "Falha no cancelamento",
        "product_invoice-cancelled_successfully": "Cancelada com sucesso",
        "product_invoice-cce_error": "Erro na carta de correção",
        "product_invoice-cce_failed": "Falha na carta de correção",
        "product_invoice-cce_successfully": "Carta de correção emitida com sucesso",
        "product_invoice-issued_error": "Erro na emissão",
        "product_invoice-issued_failed": "Falha na emissão",
        "product_invoice-issued_successfully": "Emitida com sucesso",
        "product_invoice_inbound-event_raised_successfully": "Evento de NF-e gerado com sucesso",
        "product_invoice_inbound-issued_successfully": "Emitida com sucesso",
        "product_invoice_inbound_summary-event_raised_successfully": "Evento de resumo de NF-e gerado com sucesso",
        "product_invoice_inbound_summary-issued_successfully": "Resumo de NF-e emitido com sucesso",
        "product_tax-created_successfully": "Cadastrado com sucesso",
        "product_tax-creation_failed": "Falha no cadastro",
        "product_tax-custom_rules_requested": "Regra tributária requisitada",
        "service_invoice-cancelled_error": "Erro no cancelamento",
        "service_invoice-cancelled_failed": "Falha no cancelamento",
        "service_invoice-cancelled_successfully": "Cancelada com sucesso",
        "service_invoice-issued_error": "Erro na emissão",
        "service_invoice-issued_failed": "Falha na emissão",
        "service_invoice-issued_successfully": "Emitida com sucesso",
        "service_invoice-pulled": "Extraída",
        "tax_payment_form-created_successfully": "GNRE emitida com sucesso",
        "tax_payment_form-creation_failed": "Falha na emissão da GNRE",
        "tax_payment_form-creation_not_needed": "GNRE não necessária",
        "transportation_invoice_inbound-event_raised_successfully": "Evento de CT-e gerado com sucesso",
        "transportation_invoice_inbound-issued_successfully": "Emitido com sucesso"
      },
      "headers": {
        "events": "Eventos",
        "modifiedOn": "Modificado em",
        "status": "Status",
        "url": "URL"
      },
      "labels": {
        "active": "Ativo",
        "available": "Disponíveis",
        "inactive": "Inativo",
        "insecureSsl": "SSL inseguro",
        "providerDisabled": "Funcionalidade desativada",
        "secret": "Senha para autenticação da mensagem (HMAC)",
        "secureSsl": "Verificar certificado SSL",
        "selected": "Selecionados {numEvents, plural, =0 {} =1 {(# evento)} other {(# eventos)}}",
        "status": "Está ativo?",
        "url": "Endereço (URL)"
      },
      "new": {
        "error": "Erro ao criar webhook",
        "fillFields": "Preencha os dados do webhook",
        "selectType": "Selecione o tipo do webhook",
        "success": "Webhook cadastrado com sucesso!",
        "title": "Criar Webhook"
      },
      "title": "Webhooks",
      "type": {
        "consumer_invoice": "NFC-e emissão",
        "product_invoice": "NF-e emissão",
        "product_invoice_inbound": "NF-e distribuição",
        "product_tax": "Produto",
        "service_invoice": "NFS-e emissão",
        "tax_payment_form": "GNRE",
        "transportation_invoice_inbound": "CT-e distribuição"
      },
      "types": {
        "title": "Tipo de webhook"
      }
    },
    "yes": "Sim"
  }]];
});